/*App.js*/
import React, { Component } from "react";
//Import all needed Component for this tutorial
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
    Redirect
} from "react-router-dom";
import Apptutorial from "./pages/reactreduxtutorial";
import UsersPage from "./pages/homepage";
import NotFoundPage from "./pages/notfoundpage"
import Storepage from "./pages/afhalen/Storepage"

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';
import Checkoutpage from "./pages/afhalen/checkoutpage";
import SuccessPage from "./pages/afhalen/successpage";
import CateringPage from "./pages/cateringpage";
import ContactPage from "./pages/contactpage";
import AfhalenPage from "./pages/afhalenpage";
import TelefonishAfhalenPage from "./pages/telefonishafhalenpage";

import axios from "axios";
import HomePage from "./pages/homepage";
import {Store} from "@material-ui/icons";
import WokkenPage from "./pages/wokkenpage";
import RestaurantPage from "./pages/restaurantpage";
import CookieVerklaringPage from "./pages/cookieverklaringpage";
import PrivacyStatementPage from "./pages/privacystatementpage";
import ScrollToTop from "./ScrollToTop";
import localOrderingLoginPage from "./local-ordering-pages/localLoginpage";
import localHomepage from "./local-ordering-pages/localHomepage";
import localWokkenPage from "./local-ordering-pages/localWokkenpage";
import localSuccessPage from "./local-ordering-pages/localSuccesspage";
import localBakplaatPage from "./local-ordering-pages/localBakplaatpage";
import localBuffetPage from "./local-ordering-pages/localBuffetpage";
import localNagerechtenPage from "./local-ordering-pages/localNagerechtenpage";
import localDrinkenPage from "./local-ordering-pages/locakDrinkenpage";
import SoonPage from "./pages/soonpage";
import BijbaantjePage from "./pages/bijbaantjepage";
import Reservepage from "./pages/reserve/reserverenpage";
// axios.defaults.baseURL = "http://127.0.0.1:8000";

if (window.location.origin === "http://localhost:3000") {
  axios.defaults.baseURL = "http://127.0.0.1:8000";
} else {
  axios.defaults.baseURL = window.location.origin;
}

class PageRouter extends Component {
    render() {
        return (
            <Router>
                {/*Navigation Bar*/}
                <header>
                    {/*<div className="navbarimage">*/}
                    {/*    */}
                    {/*</div>*/}
                    <Navbar variant="dark" className="mainnavbar" expand="lg">
                        <Navbar.Brand as={Link} to="/" > <img style={{height:"auto", width:"30px", marginBottom:"5px", marginRight:"-2px", filter: "invert(100%)"}} src={process.env.PUBLIC_URL + '/images/home-icon3.png'} alt="Image of the restaurant"/> Wok Baarlo</Navbar.Brand>

                        {/* scaling the menu to toggle if needed */}
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />

                        {/*main menu items*/}
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link as={Link} to="/" >Home</Nav.Link>
                                <Nav.Link as={Link} to="/reserveren" >Reserveren</Nav.Link>
                                <Nav.Link as={Link} to="/wokken" >Wokken</Nav.Link>
                                <Nav.Link as={Link} to="/restaurant" >Restaurant</Nav.Link>
                                <Nav.Link as={Link} to="/afhalen" >Afhalen</Nav.Link>
                                  <Nav.Link as={Link} to="/catering" >Catering</Nav.Link>
                                {/*<Nav.Link as={Link} to="/bijbaan" >Bijbaan</Nav.Link>*/}
                                <Nav.Link as={Link} to="/contact" >Contact & Openingstijden</Nav.Link>


                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </header>

                <main className="mainpage">
                    <ScrollToTop >
                        <Switch>
                            <Route exact path="/" component={HomePage}/>
                             <Route exact path="/wokken" component={WokkenPage} />
                              <Route exact path="/restaurant" component={RestaurantPage} />
                            <Route exact path="/catering" component={CateringPage} />
                            <Route exact path="/contact" component={ContactPage} />
                            <Route exact path="/cookie-verklaring" component={CookieVerklaringPage} />
                            <Route exact path="/privacy-statement" component={PrivacyStatementPage} />
                            {/*<Route exact path="/bijbaan" component={BijbaantjePage} />*/}

                            <Route exact path="/afhalen" component={AfhalenPage} />
                            <Route exact path="/tel-afhalen" component={TelefonishAfhalenPage} />
                            {/*<Route exact path="/12online-afhalen" component={Storepage} />*/}
                            {/*<Route exact path="/12online-afhalen/checkout" component={Checkoutpage}/>*/}
                            {/*<Route exact path="/12online-afhalen/success" component={SuccessPage}/>*/}
                            <Route exact path="/online-afhalen" component={Storepage} />
                            <Route exact path="/online-afhalen/checkout" component={Checkoutpage}/>
                            <Route exact path="/online-afhalen/success" component={SuccessPage}/>
                             {/*<Route exact path="/online-afhalen" component={SoonPage} />*/}
                            {/*<Route exact path="/online-afhalen/checkout" component={SoonPage}/>*/}
                            {/*<Route exact path="/online-afhalen/success" component={SoonPage}/>*/}

                            <Route exact path="/bestel" component={localHomepage} />
                            <Route exact path="/bestel/login" component={localOrderingLoginPage} />
                            <Route exact path="/bestel/wokken" component={localWokkenPage} />
                            <Route exact path="/bestel/bakplaat" component={localBakplaatPage} />
                            <Route exact path="/bestel/buffet" component={localBuffetPage} />
                            <Route exact path="/bestel/nagerechten" component={localNagerechtenPage} />
                            <Route exact path="/bestel/drinken" component={localDrinkenPage} />

                            <Route exact path="/bestel/succes" component={localSuccessPage} />


                            <Route exact path="/reserveren" component={Reservepage} />


                            <Route> <Redirect status={301} to="/" /></Route>
                            {/*<Route component={NotFoundPage} /> /!*has to be last?*!/*/}
                        </Switch>
                    </ScrollToTop>
                </main>
                <footer className="footer">
                    <div className="container3 container">
                         <div className="row footerrow">

                             {/*<div className="col-sm-2 footer-icon-area">*/}

                             {/*    /!*<img className="footer-icon"*!/*/}
                             {/*    /!*     src={process.env.PUBLIC_URL + '/images/home-icon3-withtext.png'}*!/*/}
                             {/*    /!*     alt="Image of the restaurant"/>*!/*/}
                             {/*</div>*/}

                            <div className=" col-sm-4">
                                <h6>Contact</h6>
                                <p> Wokrestaurant Peking Garden <br/>
                                    Napoleonsbaan Noord 46 <br/>
                                    5991 NW Baarlo <br/>
                                    Tel.: 077 - 477 14 14 <br/>
                                    info@wokbaarlo.nl
                                </p>

                            </div>

                              <div className=" col-sm-5">

                                    <div>
                                         <h6>Wok openingstijden</h6>
                                    Maandag t/m zaterdag: 17.00 - 22.00
                                    <br/>
                                    Zondag : 16.00 - 22.00
                                        <br/>
                                        <em>Dinsdagen gesloten</em>
                                    </div>

                                    <div><br/>
                                        <h6>Afhalen openingstijden</h6>
                                    Maandag t/m zaterdag:	16.00 - 21.30
                                    <br/>
                                    Zondag :	12.00 - 21.30
                                        <br/>
                                        <em>Dinsdagen gesloten</em>
                                    </div>

                            </div>
                             <div className=" col-sm-3">

                                 <h6>Volg ons op</h6>
                                 <a className="a-href-no-style"
                                           href="https://www.facebook.com/WokBaarlo" target="_blank" rel="noopener noreferrer">
                                     <i className="fa fa-facebook-official followicon facebookicon"></i>
                                       Facebook</a><br/>
                                 <a className="a-href-no-style"
                                           href="https://www.tripadvisor.nl/Restaurant_Review-g1158497-d2664714-Reviews-Peking_Garden_Wok_Restaurant-Baarlo_Limburg_Province.html" target="_blank" rel="noopener noreferrer">
                                     <i className="fa fa-tripadvisor followicon"></i>
                                     Tripadvisor</a><br/>

                                     <br/>
                                     <Link className="a-href-no-style" to="/cookie-verklaring">Cookie verklaring</Link><br/>
                                     <Link className="a-href-no-style" to="/privacy-statement">Privacy statement</Link><br/>


                            </div>

                         </div>

                         <div className="row">

                            <div className="col-sm-12 copyrights">
                                <br/>
                                 	&copy; wokbaarlo.nl
                            </div>
                         </div>

                    </div>



                </footer>

            </Router>
        );
    }
}

export default PageRouter;