import React from "react";

import {
    Link,
} from "react-router-dom";
/* We simply can use an array and loop and print each user */
const TelefonishAfhalenPage = () => {
    return (

        <div>
            <div className="afhalen-banner" style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/images/afhalen-banner-1.jpg'})`,
                backgroundRepeat: 'no-repeat',
                // width:'200vh'
                // backgroundSize: '100% 700px'
            }}/>

            <div className="container2 container">
                <div className="row ">
                    <div className="col-sm-12">
                        <h2 className="subtitle-center restaurant-red">Telefonish Afhalen</h2>
                        <br/>
                        <p>Ons afhaalrestaurant staat bekend om ons dagelijks verse ingrediënten en de vlotte services.
                            Door de drukte is het wel aan te raden om uw bestelling alvast telefonisch aan ons door geven. Probeer ook onze nieuwe  <Link to="/online-afhalen">online service</Link> om bestelling door te geven!</p>
               <p>


                         <a href = {process.env.PUBLIC_URL + '/documents/WEEKMENU2020.pdf'} target = "_blank" rel="noopener noreferrer">Klik hier voor onze week menu</a>. <br/>
                         <a href = {process.env.PUBLIC_URL + '/documents/meeneemlijst.pdf'} target = "_blank" rel="noopener noreferrer">Klik hier voor onze afhaallijst</a>. <br/>

                   </p>
                   <p>
                        Wanneer u uw beslissing heeft gemaakt, bel <b>077-4771414</b>. <br/>
                   <a
                            href="http://maps.google.nl/maps?f=d&source=s_q&hl=nl&geocode=&q=Wokrestaurant+Napoleonsbaan+Noord+46++5991NW+Baarlo&sll=51.3312,6.0909&sspn=0.012077,0.030363&g=Napoleonsbaan+Noord+46+|+5991NW+Baarlo;ie=UTF8&hq=Wokrestaurant&hnear=Napoleonsbaan+Noord+46,+Baarlo,+Limburg&ll=51.3312,6.0909&spn=0.002011,0.019312&z=16&iwloc=A&daddr=Napoleonsbaan+Noord+46,+5991+NW+Baarlo+%28Wokrestaurant+Baarlo%29"
                             target = "_blank" rel="noopener noreferrer"
                        >
                            Klik hier voor een uitgebreide routeplanner.</a>
                   </p>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default TelefonishAfhalenPage;