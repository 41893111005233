
import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {Link, Redirect} from "react-router-dom";
// import {PersistFormikValues} from "formik-persist-values";


//animation



class localNagerechtenPage extends React.Component{

    constructor(){
        super();
        this.state = {
            redirect_to_login: false,
            server_error: false,
            logged_in: false,
            redirect_to_successpage: false,
            error_message: "",
            button_disabled:false,
        }
    }






    componentDidMount() {
        let token = localStorage.getItem('localOrder-token')
        if (token === null) { // make new device id
            localStorage.setItem('localOrder-token', "")
        }


        let data ;
        let loggedIn;

        axios.post('/local-order-api/is-table-logged-in/', {
            token: localStorage.getItem('localOrder-token')

        })
            .then((response) => {
                data = response.data;
                loggedIn = data["loggedin"]
                // console.log('logged in: ', data["login-accepted"])

                if (loggedIn) {
                    this.setState({
                        logged_in:true,
                    })

                } else {
                    this.setState({
                        logged_in:false,
                        redirect_to_login:true,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });


    }




    render() {
        const nagerechten_ijs = [{fullname:"121 Pistache ijs", shortname:"121 Pistache ijs"},
                        {fullname:"122 Bananen ijs", shortname:"122 Bananen ijs"},
                        {fullname:"123 Citroen ijs", shortname:"123 Citroen ijs"},
                        {fullname:"124 Vanille ijs", shortname:"124 Vanille ijs"},
                        {fullname:"125 Chocolade ijs", shortname:"125 Chocolade ijs"},
                        {fullname:"126 Aardbeien ijs", shortname:"126 Aardbeien ijs"},
                        {fullname:"127 Mokka ijs", shortname:"127 Mokka ijs"},
                        {fullname:"128 Stracciatella ijs", shortname:"128 Stracciatella ijs"},
                        {fullname:"129 Walnoot ijs", shortname:"129 Walnoot ijs"},
                        {fullname:"130 Malaga ijs", shortname:"130 Malaga ijs"}]

            const nagerechten_fruit = [
                        {fullname:"131 Druiven", shortname:"131 Druiven"},
                        {fullname:"132 Perzik", shortname:"132 Perzik"},
                        {fullname:"133 Lychee", shortname:"133 Lychee"},
                        {fullname:"134 Watermeloen", shortname:"134 Watermeloen"},
                        {fullname:"135 Aardbei", shortname:"135 Aardbei"},
                        {fullname:"136 Peren", shortname:"136 Peren"},
                        {fullname:"137 Yan mei", shortname:"137 Yan mei"},
                        {fullname:"138 Loquats (pruimen)", shortname:"138 Loquats pruimen"},
                        {fullname:"139 Cocktailfruit", shortname:"139 Cocktailfruit"},
                        {fullname:"140 Mandarijn", shortname:"140 Mandarijn"},]

            const nagerechten_gebakjes = [
                        {fullname:"141 Slagroomsoesje", shortname:"141 Slagroomsoesje"},
                        {fullname:"142 Bananen eclair", shortname:"142 Bananen eclair"},
                        {fullname:"143 Tompouce", shortname:"143 Tompouce"},
                        {fullname:"144 Muffin vanille", shortname:"144 Muffin vanille"},
                        {fullname:"145 Gebak latte machiato", shortname:"145 Gebak lat machia"},
                        {fullname:"146 Cakerol aardbei", shortname:"146 Cakerol aardbei"},
                        {fullname:"147 Muffin chocolade", shortname:"147 Muffin chocolade"},
                        {fullname:"148 Spekkoek", shortname:"148 Spekkoek"},
                        {fullname:"149 Luikse wafel", shortname:"149 Luikse wafel"},
                        {fullname:"150 Donut aardbei", shortname:"150 Donut aardbei"},
                        {fullname:"151 Gebak appelcaramel", shortname:"151 Gebak appelcaram"},
                        {fullname:"152a Chocolade cake", shortname:"152a Chocolade cake"},
                        {fullname:"152b Pistache cake", shortname:"152b Pistache cake"},
                        {fullname:"153 Chocolade eclair", shortname:"153 Chocolade eclair"},
                        {fullname:"154 Cakerol schwarzwalder", shortname:"154 Cakerol schwarzw"},
                        {fullname:"155 Donut whitey", shortname:"155 Donut whitey"},
                        {fullname:"156 Donut vanillecream", shortname:"156 Donut vanille"},
                        {fullname:"158 Gebak aardbei mini", shortname:"158 Gebak aardbei"},
                        {fullname:"159 Arretjescake", shortname:"159 Arretjescake"},
                        {fullname:"160 Lange vingers", shortname:"160 Lange vingers"},
                        {fullname:"161 Smikkelbeer spekjes", shortname:"161 Smikkelbeer spek"},
                        {fullname:"162 Wafelrolletje", shortname:"162 Wafelrolletje"},
                        {fullname:"163 Chocoladefontein", shortname:"163 Chocoladefontein"},
                        {fullname:"164 Slagroom", shortname:"164 Slagroom"}]

         const korte_nagerechten_gebakjes = []
        for (const pair of nagerechten_gebakjes) {
            korte_nagerechten_gebakjes.push(pair.shortname)
            //key will be -> 'id'
            //dictionary[key] -> 'value'
        }

          const korte_nagerechten_fruit = []
        for (const pair of nagerechten_fruit) {
            korte_nagerechten_fruit.push(pair.shortname)
            //key will be -> 'id'
            //dictionary[key] -> 'value'
        }

          const korte_nagerechten_ijs = []
        for (const pair of nagerechten_ijs) {
            korte_nagerechten_ijs.push(pair.shortname)
            //key will be -> 'id'
            //dictionary[key] -> 'value'
        }


        if (this.state.redirect_to_login) {
            // console.log("no dataa");
            return <Redirect to='/bestel/login'
            />;
        }
        if (this.state.redirect_to_successpage) { //success bestellen!
            return <Redirect  to={{
                pathname:'/bestel/succes',

            }}
            />;
        }
        if (this.state.logged_in) {
            return (
                <div className="checkout-container container">
                    <div className="checkout-row row">
                        <div className="col-sm-12">
                              <Link to="/bestel" className="btn btn-outline-secondary cart-button">

                                    Klik hier om terug naar de bestel hoofdpagina te gaan.

                               </Link>
                            <br/>
                            <br/>
                            <h2 className="subtitle-center restaurant-red">Nagerechten</h2>

                            <div className="" id="form-wrapper">
                                {/*<Link to="/" className="btn btn-outline-dark">&#x2190; Go Back</Link>*/}
                                <Formik
                                    initialValues={{naam: ''}}

                                    validationSchema={Yup.object({
                                        naam: Yup.string()
                                            .max(15, 'Te veel karakters!'),

                                        // woksaus: Yup.string()
                                        //     .max(100, 'Te veel karakters!')
                                        //     .required('Verplicht'),
                                        // // hoeveelheid: Yup.string().required("A radio option is required"),



                                    })}

                                    onSubmit={async (values, {setSubmitting}) => {
                                           if (!this.state.button_disabled) {
                                               this.setState({button_disabled: true});
                                               setSubmitting(true);
                                               // alert(JSON.stringify(values, null, 2));

                                               let ordered_nagerechten_ijs = []
                                               for (var key in values) {
                                                   if (korte_nagerechten_ijs.includes(key)) {
                                                       // console.log(key)
                                                        if (values[key] != 0) {
                                                            ordered_nagerechten_ijs.push({[key]: values[key]})
                                                        }
                                                   }

                                               }

                                               let ordered_nagerechten_fruit = []
                                               for (var key in values) {
                                                   if (korte_nagerechten_fruit.includes(key)) {
                                                       // console.log(key)
                                                        if (values[key] != 0) {
                                                            ordered_nagerechten_fruit.push({[key]: values[key]})
                                                        }
                                                   }

                                               }

                                               let ordered_nagerechten_gebakjes = []
                                               for (var key in values) {
                                                   if (korte_nagerechten_gebakjes.includes(key)) {
                                                       // console.log(key)
                                                        if (values[key] != 0) {
                                                            ordered_nagerechten_gebakjes.push({[key]: values[key]})
                                                        }
                                                   }

                                               }

                                               axios.post('/local-order-api/process-order/', {
                                                   token: localStorage.getItem('localOrder-token'),
                                                   naam: values["naam"],
                                                   woksaus: "",
                                                   hoeveelheid: "",
                                                   vlees: [],
                                                   groente: [],
                                                    buffetWarm: [],
                                                    buffetKoud: [],
                                                    buffetSushi: [],
                                                   nagerechtenGebakjes: ordered_nagerechten_gebakjes,
                                                   nagerechtenFruit: ordered_nagerechten_fruit,
                                                   nagerechtenIjs: ordered_nagerechten_ijs,
                                                   drinken: [],
                                                   foodtype: "NAGERECHTEN",


                                               })
                                                   .then((response) => {
                                                       let loggedIn;
                                                       let data;

                                                       console.log("wok is processed")
                                                       data = response.data;
                                                       loggedIn = data["loggedin"]


                                                       if (loggedIn) {
                                                           this.setState({
                                                               logged_in: true,
                                                           })

                                                           //next check if input was correct
                                                           if (response.data['input-correct']) {

                                                               //everything is good from this point onwards.
                                                               this.setState({
                                                                   server_error: false,
                                                                   redirect_to_successpage: true,

                                                               })

                                                               // //set token in localdevice.
                                                               // localStorage.setItem('localOrder-token', response.data['token'])
                                                           } else {
                                                               this.setState({server_error: true, error_message:response.data['error-message'], button_disabled: false});
                                                           }
                                                           // not logged in
                                                       } else {
                                                           this.setState({
                                                               logged_in: false,
                                                               redirect_to_login: true,
                                                           })
                                                       }


                                                       //with response, update the cart.

                                                   })
                                                   .catch((error) => {
                                                       console.log(error);
                                                       setSubmitting(false);
                                                       this.setState({button_disabled: false});
                                                   });
                                           }
                                    }}
                                >{({isSubmitting}) => (
                                    <Form className="checkoutpage-form">
                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="naam"><h4>Uw naam:</h4></label>
                                            <Field className="form-control" name="naam" type="text"/>
                                            <ErrorMessage name="naam">
                                                {msg => <div className="field-error">{msg}</div>}
                                            </ErrorMessage>
                                        </div>
                                        <br/>



                                        {/*<br/>*/}

                                        <div className="form-group">





                                            <div><label className="font-weight-bold"  htmlFor="nagerechten"><h4>Fruit:</h4></label></div>
                                                <div className="form-check "  role="group" aria-labelledby="nagerechten-group">

                                                    {nagerechten_fruit.map(product=>

                                                    <div className="select-10-numbers">
                                                 <Field className="form-control select-10-numbers-field" name={product.shortname} as="select">

                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </Field>
                                               <label className="form-check-label local-choices">{product.fullname} </label>
                                            </div>)}
                                                </div>

                                             <br/>
                                            <div><label className="font-weight-bold"  htmlFor="nagerechten"><h4>Gebakjes:</h4></label></div>
                                                 <div className="form-check "  role="group" aria-labelledby="nagerechten-group">

                                                    {nagerechten_gebakjes.map(product=>

                                                    <div className="select-10-numbers">
                                                 <Field className="form-control select-10-numbers-field" name={product.shortname} as="select">

                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </Field>
                                               <label className="form-check-label local-choices">{product.fullname} </label>
                                            </div>)}
                                                </div>

                                            <br/>
                                            <div><label className="font-weight-bold"  htmlFor="nagerechten"><h4>IJs:</h4></label></div>
                                                 <div className="form-check "  role="group" aria-labelledby="nagerechten-group">

                                                    {nagerechten_ijs.map(product=>

                                                    <div className="select-10-numbers">
                                                 <Field className="form-control select-10-numbers-field" name={product.shortname} as="select">

                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </Field>
                                               <label className="form-check-label local-choices">{product.fullname} </label>
                                            </div>)}
                                                </div>

                                            <div>
                                               {this.state.server_error ?
                                                    <button className="btn btn-danger mobile-local-bestel-button" type="submit"
                                                            disabled={this.state.button_disabled}>{this.state.error_message} <br/> Daarna kunt u hier weer klikken om te bestellen.
                                                </button> :
                                                    <button className="btn btn-primary mobile-local-bestel-button" type="submit"
                                                        disabled={this.state.button_disabled}>Klik hier om het direct te bestellen!
                                                </button> }
                                            </div>


                                        </div>
                                        {/*<p>test: naam: {this.state.name}, email: {this.state.email}, phone: {this.state.phone}, payment: {this.state.paymentoption}, tijdstip: {this.state.tijdstip} </p>*/}
                                        {/*<PersistFormikValues  name="form-data" storage='sessionStorage' persistInvalid={true} />*/}
                                    </Form>
                                )}
                                </Formik>

                            </div>


                            {/*// <div className="login-wrapper">*/}
                            {/*//     <form>*/}
                            {/*//         <label>*/}
                            {/*//             <p>Tafel nummer:</p>*/}
                            {/*//             <input type="text" />*/}
                            {/*//         </label>*/}
                            {/*//         <label>*/}
                            {/*//             <p>Wachtwoord:</p>*/}
                            {/*//             <input type="password" />*/}
                            {/*//         </label>*/}
                            {/*//         <div>*/}
                            {/*//             <button type="submit">Submit</button>*/}
                            {/*//         </div>*/}
                            {/*//     </form>*/}
                            {/*// </div>*/}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div><p>Loading...</p></div>
                </div>
            )
        }
    }
}

export default localNagerechtenPage;


