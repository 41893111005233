import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './index.css';
import App from './App';
import Apptutorial from "./pages/reactreduxtutorial";
import reactmultipagetest from "./PageRouter";
import PageRouter from "./PageRouter";


import './css/general.css'
import './css/cart.css'
import './css/products.css'
import './css/checkout.css'
import './css/homepage.css'
import './css/local-ordering.css'

// import {CookiesProvider} from "react-cookie";

ReactDOM.render(
  <React.StrictMode>
      <PageRouter />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
