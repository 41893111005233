import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {Link, Redirect} from "react-router-dom";
// import {PersistFormikValues} from "formik-persist-values";


//animation
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));


class localDrinkenPage extends React.Component {

    constructor() {
        super();
        this.state = {
            redirect_to_login: false,
            server_error: false,
            logged_in: false,
            redirect_to_successpage: false,
            error_message: "",
            button_disabled: false,
        }
    }


    componentDidMount() {
        let token = localStorage.getItem('localOrder-token')
        if (token === null) { // make new device id
            localStorage.setItem('localOrder-token', "")
        }


        let data;
        let loggedIn;

        axios.post('/local-order-api/is-table-logged-in/', {
            token: localStorage.getItem('localOrder-token')

        })
            .then((response) => {
                data = response.data;
                loggedIn = data["loggedin"]
                // console.log('logged in: ', data["login-accepted"])

                if (loggedIn) {
                    this.setState({
                        logged_in: true,
                    })

                } else {
                    this.setState({
                        logged_in: false,
                        redirect_to_login: true,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });


    }


    render() {
        const drinken = [{fullname: "Cola", shortname: "Cola"},
            {fullname: "Cola Zero", shortname: "Cola Zero"},
            {fullname: "Fanta", shortname: "Fanta"},
            {fullname: "Sprite", shortname: "Sprite"},
            {fullname: "Ijsthee", shortname: "Ijsthee"},
            {fullname: "Ijsthee green", shortname: "Ijsthee green"},
            {fullname: "Bitter lemon", shortname: "Bitter lemon"},
            {fullname: "Casis", shortname: "Casis"},
            {fullname: "Spa", shortname: "Spa"},
            {fullname: "Spa citroen", shortname: "Spa citroen"},
            {fullname: "Spa blauw", shortname: "Spa blauw"},
            {fullname: "Tonic", shortname: "Tonic"},
            {fullname: "Fristi", shortname: "Fristi"},
            {fullname: "Chocomel", shortname: "Chocomel"},
            {fullname: "Rivela", shortname: "Rivela"},
            {fullname: "Appelsap", shortname: "Appelsap"},
            {fullname: "Jus d'orange", shortname: "Jus d'orange"},
            {fullname: "Thee", shortname: "Thee"},
            {fullname: "Espresso", shortname: "Espresso"},
            {fullname: "Koffie", shortname: "Koffie"},
            {fullname: "Capuccino", shortname: "Capuccino"},
            {fullname: "Koffieverkeerd", shortname: "Koffieverkeerd"},
            {fullname: "Irish koffie", shortname: "Irish koffie"},
            {fullname: "French koffie", shortname: "French koffie"},
            {fullname: "Radler alcoholvrij", shortname: "Radler alco vrij"},
            {fullname: "Radler 2.0", shortname: "Radler 2%"},
            {fullname: "Leffe bruin", shortname: "Leffe bruin"},
            {fullname: "Hoegaarden", shortname: "Hoegaarden"},
            {fullname: "Chinese bier", shortname: "Chinese bier"},
            {fullname: "Palm", shortname: "Palm"},
            {fullname: "Donker bier", shortname: "Donker bier"},
            {fullname: "Maltbier", shortname: "Maltbier"},
            {fullname: "Bier", shortname: "Bier"},
            {fullname: "Sjoes", shortname: "Sjoes"},
            {fullname: "Halfom bier", shortname: "Halfom bier"},
            {fullname: "Witte wijn", shortname: "Witte wijn"},
            {fullname: "Rode wijn", shortname: "Rode wijn"},
            {fullname: "Zoete wijn", shortname: "Zoete wijn"},
            {fullname: "Rosé wijn", shortname: "Rose wijn"},
            {fullname: "Rode port", shortname: "Rode port"},
            {fullname: "Witte port", shortname: "Witte port"},
            {fullname: "Medium Sherry", shortname: "Medium Sherry"},
            {fullname: "Dry Sherry", shortname: "Dry Sherry"},
            {fullname: "Witte Martini", shortname: "Witte Martini"},
            {fullname: "Rode Martini", shortname: "Rode Martini"},
            {fullname: "Sake", shortname: "Sake"},
            {fullname: "Kannetje water", shortname: "Kannetje water"}]

        const shortdrink = []
        for (const pair of drinken) {
            shortdrink.push(pair.shortname)
            //key will be -> 'id'
            //dictionary[key] -> 'value'
        }
        // console.log(shortdrink)

        // ["Cola",
        //             "Cola Zero",
        //             "Fanta",
        //             "Sprite",
        //             "Ijsthee",
        //             "Ijsthee green",
        //             "Bitter lemon",
        //             "Casis",
        //             "Spa",
        //             "Spa citroen",
        //             "Spa blauw",
        //             "Fristi",
        //             "Chocomel",
        //             "Rivela",
        //             "Appelsap",
        //             "Sinasappelsap",
        //             "Thee",
        //             "Espresso",
        //             "Koffie",
        //             "Capuccino",
        //             "Koffieverkeerd",
        //             "Irish koffie",
        //             "French koffie",
        //             "Radler alcoholvrij",
        //             "Radler 2.0",
        //             "Leffe bruin",
        //             "Hoegaarden",
        //             "Chinese bier",
        //             "Palm",
        //             "Donker bier",
        //             "Maltbier",
        //             "Bier",
        //             "Sjoes",
        //             "Halfom bier",
        //             "Witte wijn",
        //             "Rode wijn",
        //             "Zoete wijn",
        //             "Rosé wijn",
        //             "Rode port",
        //             "Witte port",
        //             "Medium Sherry",
        //             "Dry Sherry",
        //             "Witte Martini",
        //             "Rode Martini",
        //             "Sake",
        //             "Kannetje water"]


        if (this.state.redirect_to_login) {
            // console.log("no dataa");
            return <Redirect to='/bestel/login'
            />;
        }
        if (this.state.redirect_to_successpage) { //success bestellen!
            return <Redirect to={{
                pathname: '/bestel/succes',

            }}
            />;
        }
        if (this.state.logged_in) {
            return (
                <div className="checkout-container container">

                    <div className="checkout-row row">
                        <div className="col-sm-12">
                            <Link to="/bestel" className="btn btn-outline-secondary cart-button">

                                    Klik hier om terug naar de bestel hoofdpagina te gaan.

                               </Link>
                            <br/>
                            <br/>
                            <h2 className="subtitle-center restaurant-red">Drinken</h2>

                            <div className="" id="form-wrapper">
                                {/*<Link to="/" className="btn btn-outline-dark">&#x2190; Go Back</Link>*/}
                                <Formik
                                    initialValues={{naam: ''}}

                                    validationSchema={Yup.object({
                                        naam: Yup.string()
                                            .max(15, 'Te veel karakters!'),

                                        // woksaus: Yup.string()
                                        //     .max(100, 'Te veel karakters!')
                                        //     .required('Verplicht'),
                                        // // hoeveelheid: Yup.string().required("A radio option is required"),


                                    })}

                                    onSubmit={async (values, {setSubmitting}) => {

                                        if (!this.state.button_disabled) {
                                            this.setState({button_disabled: true});

                                            // alert(JSON.stringify(values, null, 2));
                                            // await sleep(5000); //make it slow
                                            setSubmitting(true);
                                            let ordered_drinks = []
                                            for (var key in values) {
                                                if (shortdrink.includes(key)) {
                                                    // console.log(key)
                                                    if (values[key] != 0) {
                                                        ordered_drinks.push({[key]: values[key]})
                                                    }
                                                }

                                            }
                                            // console.log("result")
                                            // console.log(ordered_drinks)

                                            axios.post('/local-order-api/process-order/', {
                                                token: localStorage.getItem('localOrder-token'),
                                                naam: values["naam"],
                                                woksaus: "",
                                                hoeveelheid: "",
                                                vlees: [],
                                                groente: [],
                                                 buffetWarm: [],
                                                    buffetKoud: [],
                                                    buffetSushi: [],
                                                nagerechten: [],
                                                drinken: ordered_drinks,
                                                foodtype: "DRINKEN",


                                            })
                                                .then((response) => {
                                                    let loggedIn;
                                                    let data;

                                                    console.log("wok is processed")
                                                    data = response.data;
                                                    loggedIn = data["loggedin"]


                                                    if (loggedIn) {
                                                        this.setState({
                                                            logged_in: true,
                                                        })

                                                        //next check if input was correct
                                                        if (response.data['input-correct']) {

                                                            this.setState({
                                                                    server_error: false,
                                                                    redirect_to_successpage: true,

                                                            })

                                                            // //set token in localdevice.
                                                            // localStorage.setItem('localOrder-token', response.data['token'])
                                                        } else {
                                                            this.setState({server_error: true, error_message:response.data['error-message'], button_disabled: false});
                                                        }
                                                        // not logged in
                                                    } else {
                                                        this.setState({
                                                            logged_in: false,
                                                            redirect_to_login: true,
                                                        })
                                                    }


                                                    //with response, update the cart.

                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    setSubmitting(false);
                                                    this.setState({button_disabled: false});
                                                });


                                        }
                                    }}
                                >{({isSubmitting}) => (
                                    <Form className="checkoutpage-form">
                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="naam"><h4>Uw naam:</h4></label>
                                            <Field className="form-control" name="naam" type="text"/>
                                            <ErrorMessage name="naam">
                                                {msg => <div className="field-error">{msg}</div>}
                                            </ErrorMessage>
                                        </div>
                                        <br/>


                                        <br/>

                                        <div className="form-group">

                                            <div><label className="font-weight-bold" htmlFor="drinken"><h4>Drinken:</h4></label>
                                            </div>
                                            <div className="form-check " role="group" aria-labelledby="drink-group">

                                                {drinken.map(product =>

                                                    <div className="select-10-numbers">
                                                        <Field className="form-control select-10-numbers-field"
                                                               name={product.shortname} as="select">

                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </Field>
                                                        <label
                                                            className="form-check-label local-choices">{product.fullname} </label>
                                                    </div>
                                                )}
                                            </div>

                                        </div>


                                        <div>
                                            {this.state.server_error ?
                                                    <button className="btn btn-danger mobile-local-bestel-button" type="submit"
                                                            disabled={this.state.button_disabled}>{this.state.error_message} <br/> Daarna kunt u hier weer klikken om te bestellen.
                                                </button> :
                                                    <button className="btn btn-primary mobile-local-bestel-button" type="submit"
                                                        disabled={this.state.button_disabled}>Klik hier om het direct te bestellen!
                                                </button> }
                                        </div>


                                        {/*<p>test: naam: {this.state.name}, email: {this.state.email}, phone: {this.state.phone}, payment: {this.state.paymentoption}, tijdstip: {this.state.tijdstip} </p>*/}
                                        {/*<PersistFormikValues  name="form-data" storage='sessionStorage' persistInvalid={true} />*/}
                                    </Form>
                                )}
                                </Formik>

                            </div>


                            {/*// <div className="login-wrapper">*/}
                            {/*//     <form>*/}
                            {/*//         <label>*/}
                            {/*//             <p>Tafel nummer:</p>*/}
                            {/*//             <input type="text" />*/}
                            {/*//         </label>*/}
                            {/*//         <label>*/}
                            {/*//             <p>Wachtwoord:</p>*/}
                            {/*//             <input type="password" />*/}
                            {/*//         </label>*/}
                            {/*//         <div>*/}
                            {/*//             <button type="submit">Submit</button>*/}
                            {/*//         </div>*/}
                            {/*//     </form>*/}
                            {/*// </div>*/}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div><p>Loading...</p></div>
                </div>
            )
        }
    }
}

export default localDrinkenPage;


