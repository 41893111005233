import React, {Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import axios from "axios";

export default class Category extends Component {

    constructor(){
        super();
        this.state = {
            category_list:[]
        }
    }


     componentDidMount() {

        let categories ;

        axios.get('/api/category-list/')
        .then(response => {
            categories = response.data["categories"];
            categories.sort((a, b) => a.OrderPriority < b.OrderPriority ? 1 : -1)
             // console.log('catdata:', categories)
            this.setState({
                category_list : categories,
            });
        })
        .catch(err => {})


    }


    render() {
        return (
            <div className="category">
                {/*<h3 style={{"textAlign": "center"}}>CATEGORY</h3>*/}
                <VerticalTabs value={this.props.category} onChange={this.props.categorizeProducts} categories={this.state.category_list}/>
            </div>
        );
    }
}


// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     // 'aria-controls': `vertical-tabpanel-${index}`,
//   };
// }

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F2F7F2",
    // display: 'flex',
    // height: 224,
    //   justifyContent:"flex-start",
      marginTop: "73px"


  },
  tabs: {
    borderLeft: `1px solid ${theme.palette.divider}`,

  },
  indicator: {
    left: "0px",

  },
  tab: {
      textAlign:"left",

  },
  tabwrapper:{
      alignItems:"flex-start",
      marginLeft:"5px;"
  }


}));

function VerticalTabs(props) {
  const classes = useStyles();
  // const [value, setValue] = React.useState(0);
  //
  // const handleChange = (event, newValue) => {
  //     props.onChange(event)
  //     setValue(newValue);
  // };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        // variant="scrollable"
        value={props.value}
        onChange={props.onChange}
        className={classes.tabs}
        indicatorColor = 'secondary'
        classes={{
            indicator:classes.indicator
      }}

      >
          <Tab className={classes.tab} classes={{wrapper:classes.tabwrapper}} key={'Populaire gerechten'} value={'Populaire gerechten'} label={'Populaire gerechten'} index={`vertical-tab-popularmenu`}  />
          {props.categories.map((obj,i) =>
          <Tab className={classes.tab} classes={{wrapper:classes.tabwrapper}} key={obj.id} value={obj.title} label={obj.title} index={`vertical-tab-${i}`}  />
          )}
        {/*<Tab value="ALL" label="ALL"  />*/}
        {/*<Tab value="1" label="1"  />*/}
        {/*<Tab value="bbb" label="Item Three"  />*/}
        {/*<Tab label="Item Four"  />*/}
        {/*<Tab label="Item Five"  />*/}
        {/*<Tab label="Item Six"  />*/}
        {/*<Tab label="Item Seven"  />*/}
      </Tabs>

    </div>
  );
}
