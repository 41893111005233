
import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {Redirect} from "react-router-dom";
// import {PersistFormikValues} from "formik-persist-values";


//animation



class localOrderingLoginPage extends React.Component{

    constructor(){
        super();
        this.state = {
            redirect: false,
            server_error: false,
            button_disabled: false,
            logged_in: false,
            captcha_error: false,
        }
    }






    componentDidMount() {
        let token = localStorage.getItem('localOrder-token')
        if (token === null) { // make new device id
            localStorage.setItem('localOrder-token', "")
        }


        let data ;
        let loggedIn;

        axios.post('/local-order-api/is-table-logged-in/', {
            token: localStorage.getItem('localOrder-token')

        })
            .then((response) => {
                data = response.data;
                loggedIn = data["loggedin"]
                // console.log('logged in: ', data["login-accepted"])

                if (loggedIn) {
                    this.setState({
                        logged_in:true,
                    })

                } else {
                    this.setState({
                        logged_in:false,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });


    }




    render() {

        if (this.state.redirect || this.state.logged_in) {
            return <Redirect  to={{
                pathname:'/bestel',

            }}
            />;
        }


        return(
            <div className="checkout-container container">
                <div className="checkout-row row">
                    <div className="col-sm-12">
                        <div className="" id="form-wrapper">
                            {/*<Link to="/" className="btn btn-outline-dark">&#x2190; Go Back</Link>*/}
                            <Formik
                                initialValues={{ tafelnummer: '', code: ''}}

                                validationSchema={Yup.object({
                                    tafelnummer: Yup.string()
                                        .max(100, 'Te veel karakters!')
                                        .required('Verplicht'),

                                    code: Yup.string()
                                        .max(100, 'Te veel karakters!')
                                        .required('Verplicht'),



                                })}

                                onSubmit={async (values, {setSubmitting}) => {
                                    if (!this.state.button_disabled) {
                                        this.setState({button_disabled: true});
                                        window.grecaptcha.ready(() => {
                                            window.grecaptcha.execute('6Le2MiEbAAAAAGUQk5whRT9Umi1q_ECftCZ_SuZK', {action: 'locallogin'}).then((token) => {
                                                // Send form value as well as token to the server

                                                setSubmitting(true);
                                                // await sleep(500); make it slow
                                                axios.post('/local-order-api/table-log-in/', {
                                                    tableNR: values["tafelnummer"],
                                                    code: values["code"],
                                                    captchaToken: token,

                                                })
                                                    .then((response) => {


                                                        console.log("order is processed")
                                                        console.log(response.data['correct-combination'])

                                                        if (response.data['correct-combination']) {
                                                            //set token in localdevice.
                                                            localStorage.setItem('localOrder-token', response.data['token'])

                                                            this.setState({
                                                                captcha_error: false,
                                                                server_error: false,
                                                                redirect: true,

                                                            })


                                                        } else {
                                                            if (response.data['captcha-error']) {
                                                                this.setState({captcha_error: true})
                                                            }

                                                            else{
                                                                this.setState({server_error: true})
                                                            }
                                                            this.setState({button_disabled: false});
                                                        }
                                                        //with response, update the cart.


                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        setSubmitting(false);
                                                        this.setState({button_disabled: false});
                                                    });

                                            });
                                        });
                                    }
                                }}
                            >{({ isSubmitting }) => (
                                <Form className="checkoutpage-form">
                                    <div className="form-group">
                                        <label className="font-weight-bold" htmlFor="tafelnummer">Tafel nummer</label>
                                        <Field className="form-control" name="tafelnummer" type="text" />
                                        <ErrorMessage name="tafelnummer">
                                            { msg => <div className="field-error">{msg}</div> }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                        <label className="font-weight-bold"  htmlFor="code">Code</label>
                                        <Field className="form-control" name="code" type="text" />
                                        <ErrorMessage name="code" >
                                            { msg => <div className="field-error">{msg}</div> }
                                        </ErrorMessage>
                                    </div>


                                    <div>
                                        <button  data-action='locallogin' className="btn btn-primary checkout-button" type="submit" disabled={this.state.button_disabled}>Inloggen</button>
                                        <p style={{"color":"#d0d0d0"}}><small>Deze site wordt beschermd door reCAPTCHA en dus zijn de Google
                                            {" "}<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacybeleid</a> en
                                            {" "}<a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Servicevoorwaarden</a> van toepassing.</small></p>
                                        {this.state.server_error && <p className="field-error">Combinatie is niet correct.</p>}
                                        {this.state.captcha_error && <p className="field-error">Bent u een bot? Probeer uw code helemaal opnieuw in te vullen. Als het nog steeds niet werkt, vraag dan aan de bediening om uw code te resetten.</p>}
                                    </div>
                                    {/*<p>test: naam: {this.state.name}, email: {this.state.email}, phone: {this.state.phone}, payment: {this.state.paymentoption}, tijdstip: {this.state.tijdstip} </p>*/}
                                    {/*<PersistFormikValues  name="form-data" storage='sessionStorage' persistInvalid={true} />*/}
                                </Form>
                            )}
                            </Formik>

                        </div>



                        {/*// <div className="login-wrapper">*/}
                        {/*//     <form>*/}
                        {/*//         <label>*/}
                        {/*//             <p>Tafel nummer:</p>*/}
                        {/*//             <input type="text" />*/}
                        {/*//         </label>*/}
                        {/*//         <label>*/}
                        {/*//             <p>Wachtwoord:</p>*/}
                        {/*//             <input type="password" />*/}
                        {/*//         </label>*/}
                        {/*//         <div>*/}
                        {/*//             <button type="submit">Submit</button>*/}
                        {/*//         </div>*/}
                        {/*//     </form>*/}
                        {/*// </div>*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default localOrderingLoginPage;


