
import React from 'react';
import {Link, Redirect} from "react-router-dom";

import axios from 'axios';
import CartOfSuccesspage from "../components/CartOfSuccesspage";
//animation



class localHomepage extends React.Component{

    constructor(){
        super();
        this.state = {
            logged_in: false,
            redirect: false, //redirect to login if not logged in.

        }
    }






    componentDidMount() {

        //initialize localstorage token with empty string if it doesnt exist
        let token = localStorage.getItem('localOrder-token')
        if (token === null) { // make new device id
            localStorage.setItem('localOrder-token', "")
        }


        let data ;
        let loggedIn;

        axios.post('/local-order-api/is-table-logged-in/', {
            token: localStorage.getItem('localOrder-token')

        })
            .then((response) => {
                data = response.data;
                loggedIn = data["loggedin"]
                // console.log('logged in: ', data["login-accepted"])

                if (loggedIn) {
                    this.setState({
                        logged_in:true,
                    })

                } else {
                    this.setState({
                        redirect:true,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }




    render() {
        if (this.state.redirect) {
            // console.log("no dataa");
            return <Redirect to='/bestel/login'
            />;
        }

        if (this.state.logged_in) {
            return (
                <div className="container2 container">
                    <div className="row ">
                        <div className="col-sm-12">
                            <h2 className="subtitle-center restaurant-red">Lokale Online Service</h2>
                            <br/>
                            Dit is een tijdelijke service, wat op korte termijn gemaakt is.
                        </div>
                    </div>
                    <br/>
                    <div className="row">

                        <div className=" col-sm-12 center-columns">

                            <div className="col-sm-4 afhaalbutton">
                                <Link to="/bestel/drinken" className="btn btn-success cart-button">

                                    <div> <p>Drinken</p>
                                    </div>



                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className=" col-sm-12 center-columns">
                            <div className="col-sm-4 afhaalbutton">
                                <Link to="/bestel/wokken" className="btn btn-info cart-button">

                                    <div><p>Wokken</p>

                                    </div></Link>
                            </div>

                            <div className="col-sm-4 afhaalbutton">
                                <Link to="/bestel/bakplaat" className="btn btn-danger cart-button">

                                    <div> <p>Bakplaat</p>
                                    </div>



                                </Link>
                            </div>


                        </div>

                    </div>

                    <div className="row">

                        <div className=" col-sm-12 center-columns">
                            <div className="col-sm-4 afhaalbutton">
                                <Link to="/bestel/buffet" className="btn btn-secondary cart-button">

                                    <div><p>Buffet</p>

                                    </div></Link>
                            </div>

                            <div className="col-sm-4 afhaalbutton">
                                <Link to="/bestel/nagerechten" className="btn btn-dark cart-button">

                                    <div> <p>Nagerechten</p>
                                    </div>



                                </Link>
                            </div>
                        </div>
                    </div>

                </div>


            )
        } else {
            return (
                <div>
                    <div><p>Loading...</p></div>
                </div>
            )
        }

    }
}

export default localHomepage;


