import React, {Component} from "react";
import axios from "axios";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {makeStyles} from "@material-ui/core/styles";
import Products from "./Products";

export default class ProductAccordion extends Component {

    constructor(){
        super();
        this.state = {
            category_list:[]
        }
    }


     componentDidMount() {

        let categories ;

        axios.get('/api/category-list/')
        .then(response => {
            categories = response.data["categories"];
            categories.sort((a, b) => a.OrderPriority < b.OrderPriority ? 1 : -1)
             // console.log('catdata:', categories)
            this.setState({
                category_list : categories,
            });
        })
        .catch(err => {})


    }


    render() {
        return (
            <div className="category">
                {/*<h3 style={{"textAlign": "center"}}>CATEGORY</h3>*/}
                <ControlledAccordions
                    value={this.props.category}
                    onChange={this.props.categorizeProducts}
                    categories={this.state.category_list}

                    popular_products={this.props.popular_products}
                    data={this.props.data}
                    nasi_bami_options={this.props.nasi_bami_options}
                    keuzegerechten_options={this.props.keuzegerechten_options}
                    kleinegerechten_options={this.props.kleinegerechten_options}
                    updateCart={this.props.updateCart}

                />
            </div>
        );
    }
}


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
      borderTop: "1px solid #c4ccd4",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    // flexShrink: 0,
      padding:'5px'
  },
  secondaryHeading: {
    // fontSize: theme.typography.pxToRem(15),
    // color: theme.palette.text.secondary,
  },
  MuiAccordionroot: {
      backgroundColor: "#F2F7F2",
      borderBottom: "1px solid #c4ccd4",
    },
  MuiAccordionDetails: {
      backgroundColor: "#F2F7F2",
    },
    TypographyWidth: {
      width:"100%",
    }
}));


function ControlledAccordions(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    props.onChange(event, panel);
    // console.log("ACCORDION event", event);
    // console.log("ACCORDION key", key);
    // console.log("ACCORDION panel", panel);
    // console.log("ACCORDION isExpanded", isExpanded)
  };

  return (
    <div className={classes.root}>
      <Accordion key={'Populaire gerechten'} value={'Populaire gerechten'} elevation={0}  onChange={handleChange('Populaire gerechten')} classes={{root: classes.MuiAccordionroot}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="restaurant-red" style={{fontSize:"xxx-large"}} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
            <Typography className={classes.heading}><h3 >Populaire gerechten</h3 ></Typography>
          {/*<Typography className={classes.secondaryHeading}>I am an accordion</Typography>*/}
        </AccordionSummary>

        <AccordionDetails classes={{root: classes.MuiAccordionDetails}}>
          <Typography classes={{root: classes.TypographyWidth}}>
             <Products
                    popular_products={props.popular_products}
                    category={'Populaire gerechten'}
                    data={props.data}
                    nasi_bami_options={props.nasi_bami_options}
                    keuzegerechten_options={props.keuzegerechten_options}
                    kleinegerechten_options={props.kleinegerechten_options}
                    updateCart={props.updateCart}/>
          </Typography>
        </AccordionDetails>

      </Accordion>

        {props.categories.map((obj,i) =>
          // <Tab className={classes.tab} classes={{wrapper:classes.tabwrapper}} key={obj.id} value={obj.title} label={obj.title} index={`vertical-tab-${i}`}  />

                <Accordion  key={obj.id} value={obj.title} elevation={0} onChange={handleChange(obj.title)} classes={{root: classes.MuiAccordionroot}}>

                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="restaurant-red" style={{fontSize:"xxx-large"}} />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                        <Typography className={classes.heading}><h3>{obj.title}</h3></Typography>
                      {/*<Typography className={classes.secondaryHeading}>I am an accordion</Typography>*/}
                    </AccordionSummary>

                    <AccordionDetails classes={{root: classes.MuiAccordionDetails}}>
                      <Typography classes={{root: classes.TypographyWidth}}>



                         <Products
                            popular_products={props.popular_products}
                            category={obj.title}
                            data={props.data}
                            nasi_bami_options={props.nasi_bami_options}
                            keuzegerechten_options={props.keuzegerechten_options}
                            kleinegerechten_options={props.kleinegerechten_options}
                            updateCart={props.updateCart}/>
                      </Typography>
                    </AccordionDetails>

                </Accordion>

          )}


        {/*<Tab className={classes.tab} classes={{wrapper:classes.tabwrapper}} key={'Populaire gerechten'} value={'Populaire gerechten'} label={'Populaire gerechten'} index={`vertical-tab-popularmenu`}  />*/}
        {/*  {props.categories.map((obj,i) =>*/}
        {/*  <Tab className={classes.tab} classes={{wrapper:classes.tabwrapper}} key={obj.id} value={obj.title} label={obj.title} index={`vertical-tab-${i}`}  />*/}
        {/*  )}*/}


    </div>
  );
}