
import React from 'react';
import axios from 'axios';
//import '../reactreduxtutorial.css';
import Products from "../../components/Products";
import Category from "../../components/Category";
import Cart from "../../components/Cart";
import store from "../../store";
import {Provider} from 'react-redux'

// import {v4 as uuidv4} from 'uuid';
import ProductAccordion from "../../components/ProductAccordion";
import {Link} from "react-router-dom";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

//animation



class Storepage extends React.Component{

    constructor(){
        super();
        this.state = {
            //import data from backend!
            // products: data.products,
            // size:""
            data: [], //unmodified data.
            products: [], //shown products in page.
            nasiorbami_options: [], //bami nasi mihoen opties.
            keuzegerechten_options:[], //Menu X keuzegerechten
            kleinegerechten_options:[], //kleine gerechten
            category: "Populaire gerechten", //category that filters data into products
            popular_products:[],

            //cart item stuff:
            cartItems: [],
            total_cost: 0,
            total_quantity: 0,

            //mobile
            isMobile: false,
        }
    }




    // removeFromCart = (product) => {
    //     const cartItems = this.state.cartItems.slice();
    //     this.setState({
    //         cartItems:cartItems.filter(x=>x.id !==product.id)}
    //         )
    //
    // }

    //action is 'add', 'decrease', or 'delete'.
    //orderitemID takes precedense over all other options. as orderitem id is unique. If no given: (="")
    updateCart = (productID, action, nasi_bami_options, keuzegerechten_options, kleinegerechten_options, orderitemID) => {
        // const cartItems = this.state.cartItems.slice();
        // let alreadyInCart = false;
        // cartItems.forEach(item =>{
        //     if(item.id === product.id){
        //         item.count++;
        //         alreadyInCart = true;
        //
        //     }
        // });
        // if(!alreadyInCart){
        //     cartItems.push({...product, count: 1});
        // }

        axios.post('/api/update-item/', {
            productID: productID,
            action: action,
            NasiOrBami: nasi_bami_options,
            keuzegerechten: keuzegerechten_options,
            kleinegerechten: kleinegerechten_options,
            orderitemID:orderitemID,
            device: localStorage.getItem('device-id')

        })
            .then((response) => {

                if (response.data['newdeviceid'] !== ""){
                    localStorage.setItem('device-id', response.data['newdeviceid'])
                }


                // console.log(response)
                // console.log(response.data['items']);
                //const cartItems =
                this.setState({cartItems: response.data['items']})
                this.setState({total_cost: response.data['total_cost']})
                this.setState({total_quantity: response.data['total_quantity']})
                //with response, update the cart.
            })
            .catch((error) => {
                // console.log(error);
            });


        // axios.get('http://localhost:8000/api/product-list/')
        // .then(response => {
        //     data = response.data;
        //     console.log('data:', data)
        //     this.setState({
        //         data : data,
        //         products : data,
        //     });
        // })
        // .catch(err => {})


        //this.getCart();

    }

    // returns cart from django
    getCart = () => {
        axios.post('/api/cart/',{

                device: localStorage.getItem('device-id'),

        })
            .then((response)=> {
                //   console.log("cartt")
                // console.log(response);
                //   //console.log(response.data[0]);
                //   console.log("endcartt")
                this.setState({cartItems: response.data['items']})
                this.setState({total_cost: response.data['total_cost']})
                this.setState({total_quantity: response.data['total_quantity']})
            })
            .catch((error)=> {
                // console.log(error);
            });
    }

    categorizeProducts = (event, newValue) => {
        // console.log("catprod123 event ",event);
        // console.log("catprod123 newvalue", newValue);
        if (newValue === "" || newValue === "Populaire gerechten"){
            this.setState({
                category: "Populaire gerechten",
                // products: this.state.popular_products,
            })
        }else{
            this.setState({
                category: newValue,
                // products: this.state.data.filter(
                //     (product) => product.category.title == newValue
                // ),
            })
        }
    };

    resize() {
        let ismobile = (window.innerWidth <= 992); //992 is good
        if (ismobile !== this.state.isMobile) {
            this.setState({isMobile: ismobile});
        }
    }

    componentDidMount() {

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        let data ;

        axios.get('/api/product-list/')
            .then(response => {
                data = response.data;
                // console.log('dataaa:', data)
                //set up the product list
                let popular_products= data["popular_products"]
                popular_products.sort((a, b) => a.OrderPriority < b.OrderPriority ? 1 : -1)
                let popular_product_list = []
                for (var i = 0; i < popular_products.length; i++) {
                    popular_product_list.push(popular_products[i].product)
                }

                this.setState({
                    data : data["products"],
                    popular_products: popular_product_list,
                    products : popular_product_list,
                    nasiorbami_options: data["nasi_or_bami"],
                    keuzegerechten_options: data["keuze_gerechten"],
                    kleinegerechten_options: data["kleine_gerechten"],
                });
                // console.log("kg", this.state.kleinegerechten_options)
                // console.log("nasiorbami_options", this.state.nasiorbami_options)
            })
            .catch(err => {})



        //deviceid.
        let deviceId = localStorage.getItem('device-id')
        if (deviceId === null) { //let device id be empty.
            deviceId = ""
            localStorage.setItem('device-id', deviceId)
        }
        // console.log("device id:", deviceId)


        //update cart
        this.getCart()


    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }



    render(){
        return this.state.isMobile ?
            //MOBILE
            (
                <div className="mobile-storepage">
                    <div className="afhalen-banner" style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/afhalen-banner-1.jpg'})`,
                        backgroundRepeat: 'no-repeat',
                        // width:'200vh'
                        // backgroundSize: '100% 700px'
                    }}/>
                    <div className="container">

                        <div className="row">
                            <div  style={{"marginBottom":"25px"}}  className="announcement border">
                                <div className=" col-sm-12">

                                    <div>

                                        <b>Welkom bij onze online afhaal service. Let op, u kunt alleen voor VANDAAG bezorgen. Ook is dit geen bezorg service, u moet nog steeds zelf komen ophalen.</b><br/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content row">
                            <div className=" col-sm-12">
                                <ProductAccordion
                                    category={this.state.category}
                                    categorizeProducts={this.categorizeProducts}

                                    // products={this.state.products}
                                    popular_products = {this.state.popular_products}
                                    data={this.state.data}
                                    nasi_bami_options={this.state.nasiorbami_options}
                                    keuzegerechten_options={this.state.keuzegerechten_options}
                                    kleinegerechten_options={this.state.kleinegerechten_options}
                                    updateCart={this.updateCart}/>
                            </div>
                        </div>

                    </div>
                     {this.state.cartItems.length!==0 &&(
                        <Link to="/online-afhalen/checkout" className="btn btn-success mobile-checkout-button">

                            <div className="shopping-icon-and-quantity form-inline my-2 my-lg-0">
                                <ShoppingBasketIcon id="shopping-icon"/>
                                <p id="cart-total">{this.state.total_quantity}</p>
                            </div>


                        Winkelmandje
                         (€{this.state.total_cost.toFixed(2)})</Link>)}


                </div>








            ):

            //DESKTOP
            (
                <div>

                    <div className="afhalen-banner" style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/afhalen-banner-1.jpg'})`,
                        backgroundRepeat: 'no-repeat',
                        // width:'200vh'
                        // backgroundSize: '100% 700px'
                    }}/>


                    <div className="container">

                        <div className="row">
                            <div className="announcement border">
                                <div className=" col-sm-12">

                                    <div>

                                        <b>Welkom bij onze online afhaal service. Let op, u kunt alleen voor VANDAAG bezorgen. Ook is dit geen bezorg service, u moet nog steeds zelf komen ophalen.</b><br/>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="content row">
                            <div className="col-lg-2">
                                <Category
                                    category={this.state.category}
                                    categorizeProducts={this.categorizeProducts}
                                />
                            </div>

                            <div className="main col-lg-6">
                                <div className="product-banner restaurant-red">
                                    <h3>{this.state.category}</h3>
                                </div>

                                <Products
                                    category={this.state.category}
                                    // categorizeProducts={this.categorizeProducts}

                                    // products={this.state.products}
                                    popular_products = {this.state.popular_products}
                                    data={this.state.data}
                                    nasi_bami_options={this.state.nasiorbami_options}
                                    keuzegerechten_options={this.state.keuzegerechten_options}
                                    kleinegerechten_options={this.state.kleinegerechten_options}
                                    updateCart={this.updateCart}/>
                            </div>
                            <div className="sidebar col-lg-4">
                                <div className="box border storepage-outer-cart">
                                    <Cart
                                        checkoutpage = {false}
                                        cartItems={this.state.cartItems}
                                        total_cost = {this.state.total_cost}
                                        total_quantity = {this.state.total_quantity}
                                        updateCart={this.updateCart} />
                                </div>
                            </div>
                        </div>



                    </div>
                </div>

            )
    }
}

export default Storepage;


