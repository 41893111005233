import React from "react";
/* We simply can use an array and loop and print each user */
const PrivacyStatementPage = () => {
  return (
    <div className="container">
        <h1>Privacy Statement</h1>
        <p>
     Wok Baarlo, gevestigd aan Napoleonsbaan Noord 46, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
</p>

        <p>
Contactgegevens:<br/>

https://www.wokbaarlo.nl<br/>

Napoleonsbaan Noord 46<br/>

077-4771414
            </p>


<h6>Persoonsgegevens die wij verwerken</h6>
        <p>
Wok Baarlo verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt.
        Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:
        <br/>
- Voor- en achternaam<br/>
- Telefoonnummer<br/>
- E-mailadres<br/>
            </p>

<h6>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h6>
        <p>
Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar.
        Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is.
        Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over
        kinderen verzameld worden zonder ouderlijke toestemming. Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke
        gegevens hebben verzameld over een minderjarige, neem dan contact met ons op, dan verwijderen wij deze informatie.</p>

<h6>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h6>
        <p>
Wok Baarlo verwerkt uw persoonsgegevens voor de volgende doelen:
        <br/>
- Het afhandelen van uw betaling

            </p>

        <h6>Geautomatiseerde besluitvorming</h6>
        <p>
De persoonsgegevens waar Wok Baarlo mee werkt worden altijd door een mens (bijvoorbeeld een medewerker van Wok Baarlo) verwerkt. Wok Baarlo neemt geen,
            op basis van geautomatiseerde verwerkingen, besluiten over zaken die gevolgen kunnen hebben voor personen.
            Het gaat hier om besluiten die worden genomen door computerprogramma’s of –systemen.
</p>


<h6>Hoe lang we persoonsgegevens bewaren</h6>
        <p>
Wok Baarlo bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld.
            Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens: 2 jaar
            </p>

<h6>Delen van persoonsgegevens met derden</h6>
        <p>
Wok Baarlo verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting.</p>

<h6>Cookies, of vergelijkbare technieken, die wij gebruiken</h6>
        <p>
Wok Baarlo gebruikt alleen technische en functionele cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone.
        De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak.
        Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen.
        Ook kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat.
        Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.
</p>
        <h6>
Gegevens inzien, aanpassen of verwijderen</h6>
        <p>
U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen.
        Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te
        trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door Wok Baarlo
        en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt
        indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie,
        te sturen. U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot
        intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens. Om er zeker van
        te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen.
        Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart.
        Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek.
        Wok Baarlo wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder,
        de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
</p>
        <h6>
Hoe wij persoonsgegevens beveiligen</h6>
        <p>
Wok Baarlo neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact met ons op.
    </p>
        </div>
  );
};

export default PrivacyStatementPage;