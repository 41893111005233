import React from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
//import '../reactreduxtutorial.css';
// import {v4 as uuidv4} from 'uuid';
import Cart from "../../components/Cart";

import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import "yup-phone";
import {PersistFormikValues} from 'formik-persist-values';



// wait function to test slow connections.
// const sleep = (ms) => new Promise((r) => setTimeout(r, ms));


class Reservepage extends React.Component {

    constructor() {
        super();
        this.state = {

            //form stuff
            // name: "",
            // email: "",
            // phone: "",
            // paymentoption: "",
            // tijdstip: "",

            redirect: false,
            server_error: false,

            error_message: "",
            button_disabled: false,


            // when redirect is true, submit this:
            customer_name: "",
            customer_email: "",
            customer_phone: "",
            aantal:"",
            date: "",
            tijdstip: "",


            //reservation stuff

            limits:[],
            UnavailableTimesUpdated: false,

            hours : [],
            minutes : [],

            chosen_date: "",
            chosen_hour: "",
            chosen_minute: "",
            chosen_time:"",
            availabletimes:[],


        }
        // this.handleDateChange = this.handleDateChange.bind(this);
    }


    componentDidMount() {
        this.loadUnavailableTimes()
    }




    // returns cart from django
    loadUnavailableTimes = () => {
        axios.get('/reserve-api/unavailabletimes/', {})
            .then((response) => {
                // console.log("unavailable timess")
                // console.log(response);
                //   //console.log(response.data[0]);
                //   console.log("endcartt")
                this.setState({UnavailableTimesUpdated: true, limits: response.data['limits']})
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    handleDateChange(event) {
        // this.setState({chosen_date: event.option.value});
        // console.log("IT HAPPEEND??", event.target.value)
        this.setState({chosen_date:event.target.value, chosen_time:""})

        let chosen_day = event.target.value.split(" ")[1].split("-")[0]
        let chosen_month  = event.target.value.split(" ")[1].split("-")[1]

        //get all the unavailable of the chosen date.
        let found_limits =[]
        let currentDate = new Date()
        for (let i = 0; i < this.state.limits.length; i++) {

            let split_date = this.state.limits[i].reserve_date.split("-")
            let limitday = split_date[2]
            let limitmonth = split_date[1]

            if (parseInt(limitday)==parseInt(chosen_day) && parseInt(chosen_month)==parseInt(limitmonth)){

                var startDate = new Date(currentDate.getTime());
                startDate.setHours(this.state.limits[i].reserve_time_start.split(":")[0]);
                startDate.setMinutes(this.state.limits[i].reserve_time_start.split(":")[1]);
                startDate.setSeconds(this.state.limits[i].reserve_time_start.split(":")[2]);

                var endDate = new Date(currentDate.getTime());
                endDate.setHours(this.state.limits[i].reserve_time_end.split(":")[0]);
                endDate.setMinutes(this.state.limits[i].reserve_time_end.split(":")[1]);
                endDate.setSeconds(this.state.limits[i].reserve_time_end.split(":")[2]);
                found_limits.push([startDate, endDate])
            }
        }
        // console.log("proccessed limits",found_limits)


        // modify the times!

        let sunday = event.target.value.split(" ")[0]
        if (sunday == "zondag") {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        } else {
            var hours = ["17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        //holiday exception:  #also change in views!
        if (chosen_day == 1 & chosen_month ==1) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 20 & chosen_month ==4 & new Date().getFullYear() == 2025) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 5 & chosen_month ==4 & new Date().getFullYear() == 2026) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 28 & chosen_month ==3 & new Date().getFullYear() == 2027) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 16 & chosen_month ==4 & new Date().getFullYear() == 2028) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 1 & chosen_month ==4 & new Date().getFullYear() == 2029) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 26 & chosen_month ==4 & new Date().getFullYear() == 2025) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 27 & chosen_month ==4 ) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 9 & chosen_month ==5 & new Date().getFullYear() == 2024) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 29 & chosen_month ==5 & new Date().getFullYear() == 2025) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 14 & chosen_month ==5 & new Date().getFullYear() == 2026) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 6 & chosen_month ==5 & new Date().getFullYear() == 2027) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 25 & chosen_month ==5 & new Date().getFullYear() == 2028) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 10 & chosen_month ==5 & new Date().getFullYear() == 2029) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }
        
        //pinksteren 2 dag
        if (chosen_day == 20 & chosen_month == 5 & new Date().getFullYear() == 2024) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 9 & chosen_month ==6 & new Date().getFullYear() == 2025) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 25 & chosen_month ==5 & new Date().getFullYear() == 2026) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 17 & chosen_month ==5 & new Date().getFullYear() == 2027) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 5 & chosen_month ==6 & new Date().getFullYear() == 2028) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 21 & chosen_month ==5 & new Date().getFullYear() == 2029) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }


        if (chosen_day == 25 & chosen_month ==12 ) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }

        if (chosen_day == 26 & chosen_month ==12 ) {
            var hours = ["16", "17", "18", "19", "20"]
            var minutes = ["00", "15", "30", "45"]
        }


        let times = []
        if (found_limits.length > 0){
            for (let i = 0; i < hours.length; i++) {
                for (let j = 0; j < minutes.length; j++) {



                    //check if the current hour is in unavailable times.
                    let currentDate = new Date()
                    currentDate.setHours(hours[i]);
                    currentDate.setMinutes(minutes[j]);
                    currentDate.setSeconds(0)

                    //skip if the hour is pased.
                     if (chosen_day == new Date().getDate()){//today
                            if(currentDate<new Date()){
                                continue
                            }
                     }

                    let notvalid = false
                    for (let k = 0; k< found_limits.length; k++){
                        if( found_limits[k][0] <= currentDate && found_limits[k][1] >= currentDate){
                            notvalid = true
                        }
                    }
                    if (!notvalid){
                        times.push(hours[i]+":"+minutes[j])
                    }

                }
            }
        } else {
            for (let i = 0; i < hours.length; i++) {
                for (let j = 0; j < minutes.length; j++) {



                    //skip if the hour is pased.
                     if (chosen_day == new Date().getDate()){//today
                         //check if the current hour is in unavailable times.
                            let currentDate = new Date()
                            currentDate.setHours(hours[i]);
                            currentDate.setMinutes(minutes[j]);
                            currentDate.setSeconds(0)
                            if(currentDate<new Date()){
                                continue
                            }
                     }
                    times.push(hours[i]+":"+minutes[j])
                }
            }
        }

        this.setState({availabletimes:times})

        // var startTime = '15:10:10';
        // var endTime = '22:30:00';

        // let currentDate = new Date()
        // currentDate.setHours(currentDate.split(":")[0]);
        // currentDate.setMinutes(currentDate.split(":")[1]);

        // if (found_limits)
        // for (let i = 0; i < this.state.limits.length; i++) {
        //
        // var startDate = new Date(currentDate.getTime());
        // startDate.setHours(startTime.split(":")[0]);
        // startDate.setMinutes(startTime.split(":")[1]);
        // startDate.setSeconds(startTime.split(":")[2]);
        //
        // var endDate = new Date(currentDate.getTime());
        // endDate.setHours(endTime.split(":")[0]);
        // endDate.setMinutes(endTime.split(":")[1]);
        // endDate.setSeconds(endDate.split(":")[2]);




        //




        //make the current time empty if date has changed.
    }

    handleTimeChange(event) {
        // console.log("lala", event.target.value)
        this.setState({chosen_time:event.target.value})

    }


    render() {
        const today_0 = new Date()
        const today_1 = new Date(); today_1.setDate(today_1.getDate() + 1);
        const today_2 = new Date(); today_2.setDate(today_2.getDate() + 2);
        const today_3 = new Date(); today_3.setDate(today_3.getDate() + 3);
        const today_4 = new Date(); today_4.setDate(today_4.getDate() + 4);
        const today_5 = new Date(); today_5.setDate(today_5.getDate() + 5);
        const today_6 = new Date(); today_6.setDate(today_6.getDate() + 6);
        const today_7 = new Date(); today_7.setDate(today_7.getDate() + 7);
        const today_8 = new Date(); today_8.setDate(today_8.getDate() + 8);
        const today_9 = new Date(); today_9.setDate(today_9.getDate() + 9);
        const today_10 = new Date(); today_10.setDate(today_10.getDate() + 10);



        const time_options = { weekday: 'long', month: 'numeric', day: 'numeric' }

        let time = new Date();
        if (this.state.timesUpdated) {
            time.setMinutes(time.getMinutes() + this.state.timeBuffer);
        }
        if (this.state.redirect) {
            return (
            <div>

                <div className="restaurant-banner" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/restaurant-banner.jpg'})`,
                    backgroundRepeat: 'no-repeat',
                    // width:'200vh'
                    // backgroundSize: '100% 700px'
                }}/>
                <div className="checkout-container container">
                    <div className="row">
                        <div style={{"marginBottom": "25px"}} className="announcement border">
                            <div className=" col-sm-12">

                                <div>

                                    <b>Welkom bij onze nieuwe online reserveer service.</b><br/>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="checkout-row row">
                        <div className="col-lg-6 mx-auto" >
                            <h2>We hebben uw reservering ontvangen! </h2>

                    <p>Ons adres is <b>Napoleonsbaan Noord 46</b>. <a
                            href="https://www.google.nl/maps/dir//Wok+Baarlo,+Napoleonsbaan+Noord+46,+5991+NW+Baarlo/@51.3317584,6.0935658,15.5z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c74fa0c89bc1db:0xc098499a2306e653!2m2!1d6.0909691!2d51.3312467!3e0"
                             target = "_blank" rel="noopener noreferrer"
                        >
                            Klik hier voor een uitgebreide routeplanner</a>. <br/> Voor problemen kunt u ons telefonisch bereiken op <b>077-4771414</b> </p>

                    <br/>
                    <h5>Uw gegevens:</h5>
                    <div className="box border">
                        <ul>
                            <li>
                                Naam: {this.state.customer_name}
                            </li>
                            <li>
                                Email: {this.state.customer_email}
                            </li>
                            <li>
                                Telefoonnummer: {this.state.customer_phone}
                            </li>
                            <li>
                                Aantal personen: {this.state.aantal}
                            </li>
                            <li>
                                datum: {this.state.date}
                            </li>
                            <li>
                                tijdstip:  {this.state.tijdstip}
                            </li>
                            {/*<li>*/}
                            {/*  Tijdstip: {this.state.tijdstip}*/}
                            {/*</li>*/}
                            {/* <li>*/}
                            {/*  Totaal kost: {this.state.final_total_cost}*/}
                            {/*</li>*/}
                        </ul>
                    </div>





                    </div>
                </div>
                </div></div>
        )
        }
        return (
            <div>

                <div className="restaurant-banner" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/restaurant-banner.jpg'})`,
                    backgroundRepeat: 'no-repeat',
                    // width:'200vh'
                    // backgroundSize: '100% 700px'
                }}/>
                <div className="checkout-container container">
                    <div className="row">
                        <div style={{"marginBottom": "25px"}} className="announcement border">
                            <div className=" col-sm-12">

                                <div>

                                    <b>Welkom bij onze nieuwe online reserveer service.</b><br/>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="checkout-row row">
                        <div className="col-lg-6 mx-auto" >
                            <p>Welkom, in dit formulier kan je een tafel reserveren bij Wok Baarlo. Voor grote groepen, bel ons a.u.b.</p>

                            <div className="" id="form-wrapper">
                                {/*<Link to="/" className="btn btn-outline-dark">&#x2190; Go Back</Link>*/}
                                <Formik
                                    initialValues={{name: '', date:'', tijdstip: '', aantal:'',email: '', phone: ''}}

                                    validationSchema={Yup.object({
                                        name: Yup.string()
                                            .max(20, 'Gebruik a.u.b. 20 letters of minder')
                                            .required('Verplicht'),

                                        email: Yup.string().email('Ongeldig e-mailadres').required('Verplicht'),
                                        phone: Yup.string()

                                            .required('Verplicht').phone("NL", true, 'Ongeldig nummer'),
                                        aantal: Yup.number().integer('Ongeldig nummer').required('Verplicht'),
                                        tijdstip: Yup.string().required('Verplicht'),
                                        date: Yup.string().required('Verplicht'),


                                    })}

                                    onSubmit={async (values, {setSubmitting}) => {

                                        if (!this.state.button_disabled) {
                                            this.setState({button_disabled: true});


                                            window.grecaptcha.ready(() => {
                                                window.grecaptcha.execute('6Le2MiEbAAAAAGUQk5whRT9Umi1q_ECftCZ_SuZK', {action: 'tablereservation'}).then((token) => {
                                                    setSubmitting(true);
                                                    // await sleep(500); make it slow
                                                    axios.post('/reserve-api/process-reservation/', {
                                                        name: values["name"],
                                                        email: values["email"],
                                                        phone: values["phone"],
                                                        aantal: values["aantal"],
                                                        tijdstip: values["tijdstip"],
                                                        date: values["date"],
                                                        captchaToken: token,

                                                    })
                                                        .then((response) => {

                                                            // console.log(response)
                                                            // console.log(response.data['items']);
                                                            //const cartItems =
                                                            // console.log("reservation is processed")


                                                            if (response.data['processed']) {
                                                                this.setState({
                                                                    server_error: false,
                                                                    redirect: true,
                                                                    customer_name: response.data['name'],
                                                                    customer_email: response.data['email'],
                                                                    customer_phone: response.data['phone'],
                                                                    aantal: response.data['aantal'],
                                                                    date: response.data['date'],
                                                                    tijdstip: response.data['tijdstip']

                                                                })
                                                                // this.setState({})
                                                            } else {
                                                                this.setState({
                                                                    server_error: true,
                                                                    error_message: response.data['errormsg']
                                                                })
                                                                this.setState({button_disabled: false});
                                                            }
                                                            //with response, update the cart.


                                                        })
                                                        .catch((error) => {
                                                            // console.log(error);
                                                            setSubmitting(false);
                                                            this.setState({button_disabled: false});
                                                        });

                                                });
                                            });
                                        }
                                    }}
                                >{({isSubmitting, handleChange }) => (
                                    <Form className="checkoutpage-form">
                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="name">Naam</label>
                                            <Field className="form-control" name="name" type="text"/>
                                            <ErrorMessage name="name">
                                                {msg => <div className="field-error">{msg}</div>}
                                            </ErrorMessage>
                                        </div>

                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="aantal">Aantal personen</label>
                                            <Field className="form-control my-select" name="aantal" as="select" >
                                                <option value=""></option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                {/*<option value="iDEAL">iDEAL</option>*/}
                                            </Field>
                                            <ErrorMessage name="aantal">
                                                {msg => <div className="field-error">{msg}</div>}
                                            </ErrorMessage>
                                        </div>

                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="date">Datum</label>
                                            <Field className="form-control my-select" name="date" as="select" value={this.state.chosen_date} onChange={(e) => {this.handleDateChange(e); handleChange(e)}}>
                                                <option value={this.state.chosen_date} >{this.state.chosen_date} </option>
                                                <option value={today_0.toLocaleDateString('nl-NL', time_options)}>{today_0.toLocaleDateString('nl-NL', time_options)}</option>
                                                <option value={today_1.toLocaleDateString('nl-NL', time_options)}>{today_1.toLocaleDateString('nl-NL', time_options)}</option>
                                                <option value={today_2.toLocaleDateString('nl-NL', time_options)}>{today_2.toLocaleDateString('nl-NL', time_options)}</option>
                                                <option value={today_3.toLocaleDateString('nl-NL', time_options)}>{today_3.toLocaleDateString('nl-NL', time_options)}</option>
                                                <option value={today_4.toLocaleDateString('nl-NL', time_options)}>{today_4.toLocaleDateString('nl-NL', time_options)}</option>
                                                <option value={today_5.toLocaleDateString('nl-NL', time_options)}>{today_5.toLocaleDateString('nl-NL', time_options)}</option>
                                                <option value={today_6.toLocaleDateString('nl-NL', time_options)}>{today_6.toLocaleDateString('nl-NL', time_options)}</option>
                                                <option value={today_7.toLocaleDateString('nl-NL', time_options)}>{today_7.toLocaleDateString('nl-NL', time_options)}</option>
                                                <option value={today_8.toLocaleDateString('nl-NL', time_options)}>{today_8.toLocaleDateString('nl-NL', time_options)}</option>
                                                <option value={today_9.toLocaleDateString('nl-NL', time_options)}>{today_9.toLocaleDateString('nl-NL', time_options)}</option>
                                                <option value={today_10.toLocaleDateString('nl-NL', time_options)}>{today_10.toLocaleDateString('nl-NL', time_options)}</option>
                                                {/*<option value="iDEAL">iDEAL</option>*/}
                                            </Field>
                                            <ErrorMessage name="date">
                                                {msg => <div className="field-error">{msg}</div>}
                                            </ErrorMessage>
                                        </div>

                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="tijdstip">Beschikbare tijden</label>
                                            <Field name="tijdstip" as="select" className="form-control my-select" value={this.state.chosen_time} onChange={(e) => {this.handleTimeChange(e); handleChange(e)}}>
                                                {/*<option value="">{new Date().getHours()+":"+new Date().getMinutes()}</option>*/}
                                                <option value=""></option>
                                                {/*<option value="00.00">test-time</option>*/}
                                                { this.state.availabletimes.map((time) =>
                                                    <option value={time}>{time}</option>

                                                )}



                                            </Field>
                                            <ErrorMessage name="tijdstip">
                                                {msg => <div className="field-error">{msg}</div>}
                                            </ErrorMessage>

                                        </div>

                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="email">E-mailadres</label>
                                            <Field className="form-control" name="email" type="text"/>
                                            <ErrorMessage name="email">
                                                {msg => <div className="field-error">{msg}</div>}
                                            </ErrorMessage>
                                        </div>

                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="phone">Telefoonnummer</label>
                                            <Field className="form-control" name="phone" type="text"/>
                                            <ErrorMessage name="phone">
                                                {msg => <div className="field-error">{msg}</div>}
                                            </ErrorMessage>
                                        </div>




                                        <div>


                                            {this.state.server_error ?


                                                <button data-action='tablereservation'
                                                        className="btn btn-danger checkout-button" type="submit"
                                                        disabled={this.state.button_disabled}>{this.state.error_message}
                                                    <br/> Daarna kunt u hier weer klikken om te reserveren.</button> :
                                                <button data-action='tablereservation'
                                                        className="btn btn-primary checkout-button" type="submit"
                                                        disabled={this.state.button_disabled}>Reserveren</button>}

                                            <p style={{"color": "#d0d0d0"}}><small>Deze site wordt beschermd door
                                                reCAPTCHA en dus zijn de Google
                                                {" "}<a href="https://policies.google.com/privacy" target="_blank"
                                                        rel="noopener noreferrer">Privacybeleid</a> en
                                                {" "}<a href="https://policies.google.com/terms" target="_blank"
                                                        rel="noopener noreferrer">Servicevoorwaarden</a> van toepassing.</small>
                                            </p>
                                            {/*{this.state.server_error &&*/}
                                            {/*<p className="field-error">Er is iets fouts gegaan, bel ons als u er niet uitkomt!</p>}*/}
                                            {this.state.captcha_error &&
                                            <p className="field-error">Bent u een bot? Probeer uw code helemaal opnieuw
                                                in te vullen. Als het nog steeds niet werkt, vraag dan aan de bediening
                                                om uw code te resetten.</p>}
                                        </div>
                                        {/*<p>test: naam: {this.state.name}, email: {this.state.email}, phone: {this.state.phone}, payment: {this.state.paymentoption}, tijdstip: {this.state.tijdstip} </p>*/}
                                        <PersistFormikValues name="reserve-data" storage='sessionStorage'
                                                             persistInvalid={true} ignoreValues={['tijdstip']}/>
                                    </Form>
                                )}
                                </Formik>

                            </div>


                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default Reservepage;