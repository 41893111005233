
import React from 'react';
import axios from 'axios';
//import '../reactreduxtutorial.css';
import Products from "../components/Products";
import Category from "../components/Category";
import Cart from "../components/Cart";
import store from "../store";
import {Provider} from 'react-redux'


//animation


class Apptutorial extends React.Component{

    constructor(){
        super();
        this.state = {
            //import data from backend!
            // products: data.products,
            // size:""
            data: [], //unmodified data.
            products: [],
            category: "ALL",
            cartItems: [],
        }
    }

    removeFromCart = (product) => {
        const cartItems = this.state.cartItems.slice();
        this.setState({
            cartItems:cartItems.filter(x=>x.id !==product.id)}
            )

    }

    addToCart = (product) => {
        const cartItems = this.state.cartItems.slice();
        let alreadyInCart = false;
        cartItems.forEach(item =>{
            if(item.id === product.id){
                item.count++;
                alreadyInCart = true;

            }
        });
        if(!alreadyInCart){
            cartItems.push({...product, count: 1});
        }
        this.setState({cartItems})
    }

    categorizeProducts = (event) => {
        if (event.target.value === "" || event.target.value === "ALL"){
            this.setState({
                category: "ALL",
                products: this.state.data
            })
        }else{
          this.setState({
              category: event.target.value,
              products: this.state.data.filter(
                  (product) => product.category == event.target.value
              ),
          })
        }
    };

    componentDidMount() {

        let data ;

        axios.get('/api/product-list/')
        .then(response => {
            data = response.data;
            console.log('data:', data)
            this.setState({
                data : data,
                products : data,
            });
        })
        .catch(err => {})
    }


    render(){
        return (
            <Provider store={store}>
                <div className="reactpage">
                    <header>
                        <a href="/">React shopping cart</a>
                    </header>

                    <main>
                        <div className="content">
                            <Category
                                category={this.state.category}
                                categorizeProducts={this.categorizeProducts}
                            />
                            <div className="main"><Products products={this.state.products} addToCart={this.addToCart}/></div>
                            <div className="sidebar">
                                <Cart cartItems={this.state.cartItems} removeFromCart={this.removeFromCart} />
                            </div>
                        </div>
                    </main>

                    <footer>
                        footerrr
                    </footer>
                </div>
            </Provider>
        )
    }
}

export default Apptutorial;