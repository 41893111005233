
import './App.css';
import React from 'react';
import axios from 'axios';

class App extends React.Component {


    constructor(props){
     super(props);
     this.state= {
        details: [],
     };
    }

    componentDidMount() {

        let data ;

        axios.get('/api/product-list/')
        .then(response => {
            data = response.data;
            console.log('data:', data)
            this.setState({
                details : data
            });
        })
        .catch(err => {})
    }

  render() {
    return(
      <div>
           {this.state.details.map((obj, id) =>  (
                <div key={id}>
                <div >
                      <div >
                            <h1>{obj.name} </h1>

                      </div>
                </div>
                </div>
            )
            )}
      </div>
      );
  }
}

export default App;