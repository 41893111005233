import React from "react";
import CartOfSuccesspage from "../../components/CartOfSuccesspage";
import {Redirect} from "react-router-dom";

class SuccessPage extends React.Component {

    constructor(){
        super();
        this.state = {
            customer_name: "",
            customer_email: "",
            customer_phone: "",
            tijdstip: "",
            final_total_cost: "",
            cartItems:[],
            ordernumber:"",
            orderID:"",

            redirect: false, //redirect back to shopping if no data received
        }


    }

   componentDidMount() {
        if (typeof this.props.location.state !== 'undefined'){
           this.setState({
           customer_name:this.props.location.state.customer_name,
           customer_email:this.props.location.state.customer_email,
           customer_phone:this.props.location.state.customer_phone,
           tijdstip:this.props.location.state.tijdstip,
           final_total_cost:this.props.location.state.final_total_cost,
           cartItems:this.props.location.state.cartItems,
           orderID: this.props.location.state.orderID,
         })
        } else {
            this.setState({
                redirect:true,
            })
            // console.log("no data");
            //  <Redirect  to={'/'}
            // />;

        }

   }

    render() {

        if (this.state.redirect) {
            // console.log("no dataa");
            return <Redirect to='/'
            />;
        }

        if (this.state.orderID != "") {
            return (
                 <div>

                   <div className="afhalen-banner" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/afhalen-banner-1.jpg'})`,
                            backgroundRepeat: 'no-repeat',
                            // width:'200vh'
                            // backgroundSize: '100% 700px'
                        }}/>
                <div className="container">
                       {/*<div className="row">*/}
                       {/*     <div    className="announcement border">*/}
                       {/*         <div className=" col-sm-12">*/}

                       {/*             <div>*/}

                       {/*                 <b>Welkom bij onze nieuwe online afhaal service. Let op, dit is geen bezorg service, u moet nog steeds zelf komen ophalen!</b><br/>*/}


                       {/*             </div>*/}
                       {/*         </div>*/}
                       {/*     </div>*/}
                       {/* </div>*/}

                    <h2>Bedankt voor uw bestelling! </h2>

                    <p>Uw bestelnummer is <b>{this.state.orderID}</b> en u kunt het afhalen rond <b>{this.state.tijdstip}!</b></p>
                    <p>Ons adres is <b>Napoleonsbaan Noord 46</b>. <a
                            href="https://www.google.nl/maps/dir//Wok+Baarlo,+Napoleonsbaan+Noord+46,+5991+NW+Baarlo/@51.3317584,6.0935658,15.5z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c74fa0c89bc1db:0xc098499a2306e653!2m2!1d6.0909691!2d51.3312467!3e0"
                             target = "_blank" rel="noopener noreferrer"
                        >
                            Klik hier voor een uitgebreide routeplanner</a>. <br/> Voor problemen kunt u ons telefonisch bereiken op <b>077-4771414</b> </p>

                    <br/>
                    <h5>Uw contactgegevens:</h5>
                    <div className="box border">
                        <ul>
                            <li>
                                Naam: {this.state.customer_name}
                            </li>
                            <li>
                                Email: {this.state.customer_email}
                            </li>
                            <li>
                                Telefoonnummer: {this.state.customer_phone}
                            </li>
                            {/*<li>*/}
                            {/*  Tijdstip: {this.state.tijdstip}*/}
                            {/*</li>*/}
                            {/* <li>*/}
                            {/*  Totaal kost: {this.state.final_total_cost}*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                    <br/>
                    <h5>Uw bestelling:</h5>
                    <div className="box border">
                        <CartOfSuccesspage
                            cartItems={this.state.cartItems}
                            total_cost={this.state.final_total_cost}
                        />
                    </div>
                </div>
                 </div>


            )
        } else {
            return (
                 <div>

                   <div className="afhalen-banner" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/afhalen-banner-1.jpg'})`,
                            backgroundRepeat: 'no-repeat',
                            // width:'200vh'
                            // backgroundSize: '100% 700px'
                        }}/>
                <div><p>Loading...</p></div>
                 </div>
            )
        }
    }



}

export default SuccessPage;