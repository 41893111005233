import React, { Component } from 'react'
import * as Yup from "yup";
import axios from "axios";
import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import {PersistFormikValues} from "formik-persist-values";
import {Friend} from "../pages/homepage";
import Collapse from 'react-bootstrap/Collapse'
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export class NasiOrBamiOption {
    constructor() {
        this.name = "";
    }
}

export class KeuzegerechtOption {
    constructor() {
        this.name = "";
    }
}


function calculateNasiBamiExtraCost(choices, NasiBamiList) {
    let finalCost = 0;
    let NasiBamiPriceDict = {}
    for (var i = 0; i < NasiBamiList.length; i++) {
        // console.log("test",NasiBamiList[i]['price'])
        NasiBamiPriceDict[NasiBamiList[i]["naam"]] = parseFloat(NasiBamiList[i]['price'])
    }
    // console.log("dict",NasiBamiPriceDict)
    //Do something
    for (var i = 0; i < choices.length; i++) {
        // console.log("test",NasiBamiList[i]['price'])
        if (choices[i]['name'] !== "") {
            finalCost += NasiBamiPriceDict[choices[i]['name']]
        }
        //Do something
    }
    return finalCost;   // The function returns the product of p1 and p2
}




function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class Products extends Component {

     constructor(){
        super();
        this.state = {
            collapsedictionary: {},
            falsedictionary:{},
            open_toast:false,

            products: [], //shown products in page.
            current_category: "",
        }
    }

    categorizeProducts = (categoryValue) => {
        // console.log("catprod123 event ",event);
        // console.log("catprod123 newvalue", newValue);
        if (categoryValue === "" || categoryValue === "Populaire gerechten"){
            this.setState({
                // category: "Populaire gerechten",
                products: this.props.popular_products,
            })
        }else{
          this.setState({
              // category: newValue,
              products: this.props.data.filter(
                  (product) => product.category.title == categoryValue
              ),
          })
        }
    };

    componentDidMount() {
        this.categorizeProducts(this.props.category)
        // console.log("PRODUCTS",this.state.products)

        let falsedictionary= {}
        this.state.products.forEach(function (product, index) {
          falsedictionary["collapse"+product.id]=false;
        });
        this.setState({
            falsedictionary: falsedictionary,
            collapsedictionary: falsedictionary,
            current_category: this.props.category
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      // Typical usage (don't forget to compare props):
      if (this.state.current_category !== this.props.category) {
        this.categorizeProducts(this.props.category)
          this.setState({
            current_category: this.props.category
        })
      }
      if (this.props.popular_products !== prevProps.popular_products){
          this.categorizeProducts(this.props.category)
      }
    }

    addbutton = (product) => {

         if (product.NasiBamiOptions>0 || product.MenuKeuzeGerechtOptions>0){
             let falsedictionary = { ...this.state.falsedictionary}
             falsedictionary["collapse"+product.id]=!this.state.collapsedictionary["collapse"+product.id]
             this.setState({
                                    collapsedictionary:falsedictionary})


         } else {
             this.props.updateCart(product.id, "add",[],[],[],"")
             this.setState({open_toast:true})
         }

    }

    createString = (NasiBamiOptions, MenuKeuzeGerechtOptions) => {
         let stringSoFar = "";

         if (MenuKeuzeGerechtOptions==0 && NasiBamiOptions==0){
             return ""
         }

         if (MenuKeuzeGerechtOptions == 1) {
             stringSoFar = stringSoFar.concat(MenuKeuzeGerechtOptions+" gerecht")
         }
         if (MenuKeuzeGerechtOptions > 1) {
             stringSoFar = stringSoFar.concat(MenuKeuzeGerechtOptions+" gerechten")
         }

         if (NasiBamiOptions == 0 && MenuKeuzeGerechtOptions>0){
            stringSoFar = stringSoFar.concat(".")
         } else if (NasiBamiOptions > 0 && MenuKeuzeGerechtOptions>0) {
             stringSoFar = stringSoFar.concat(", ")
         }

         if (NasiBamiOptions == 1) {
             stringSoFar = stringSoFar.concat(NasiBamiOptions+" nasi/bami.")
         }

         if (NasiBamiOptions > 1) {
             stringSoFar = stringSoFar.concat(NasiBamiOptions+" nasi/bami.")
         }

         return stringSoFar
    }

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.setState({
            open_toast:false,
        })
    }

    extra_option_submit = (product, values) => {
        this.setState({open_toast:true});
        this.props.updateCart(product.id, "add", values["NasiOrBamiOptions"],
            values["KeuzegerechtOptions"].slice(0, product.MenuKeuzeGerechtOptions),
            values["KeuzegerechtOptions"].slice(product.MenuKeuzeGerechtOptions)
            , "");
    }

    render(){
        return(
            <div className="products">
                {/*{console.log("THIS IS MY PRODUCT",this.state.products)}*/}
                {(this.state.products.length === 0) &&
                <div>
                    Geen gerechten gevonden
                </div>
                // :
                //     <div className={"cart car-header"}>
                //         You have {this.props.total_quantity} in the cart{" "}
                //     </div>
                }
                <ul>
                    {this.state.products.map(product=>
                        <li key={product.id}>
                            {/*onClick={() => this.props.updateCart(product.id, "add",[],[],"")}*/}
                            <div className="product border">


                                <div className=" btn-light text-left product-name-price "
                                     onClick={() => this.addbutton(product)}>

                                    <div className="product-name-price-left">
                                        <h5>{product.name}</h5>

                                        <p className="product-text-info">{product.info}</p>

                                        {(product.NasiBamiOptions>0 || product.MenuKeuzeGerechtOptions>0) &&
                                            <p className="product-text-extraoptions">Keuze uit: {this.createString(product.NasiBamiOptions, product.MenuKeuzeGerechtOptions)}</p>
                                        }

                                        <div className="product-price">
                                            <div>
                                                €{product.price}
                                                {/*<button onClick={() => this.props.updateCart(product.id, "add",[],[],"")} className="button primary">*/}
                                                {/*    Add To Cart*/}
                                                {/*</button>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-name-price-right">
                                        <AddIcon fontSize="large"/>
                                    </div>
                                </div>

                                {(product.NasiBamiOptions>0 || product.MenuKeuzeGerechtOptions>0) &&
                                    <Collapse animation="false" in={this.state.collapsedictionary["collapse"+product.id]}>
                                        <div  className="product-extra-options">
                                            <Formik

                                                initialValues={{
                                                    NasiOrBamiOptions: Array.from(new Array(product.NasiBamiOptions).fill().map((e,i) => {
                                                        return new NasiOrBamiOption() })),
                                                    KeuzegerechtOptions: Array.from(new Array(product.MenuKeuzeGerechtOptions).fill().map((e,i) => {
                                                        return new KeuzegerechtOption() })),
                                                }}

                                                validationSchema = {Yup.object().shape({
                                                    NasiOrBamiOptions: Yup.array()
                                                        .of(Yup.object().shape({
                                                            name: Yup.string().required("Kies een optie!")
                                                            // Rest of your amenities object properties
                                                        })),


                                                    KeuzegerechtOptions: Yup.array()
                                                        .of(Yup.object().shape({
                                                            name: Yup.string().required("Kies een optie!")
                                                            // Rest of your amenities object properties
                                                        }))


                                                })}

                                                // onSubmit={ (values) => {
                                                //     this.props.updateCart(product.id, "add", values["NasiOrBami"])
                                                // }}

                                                onSubmit={values =>
                                                    // setTimeout(() => {
                                                    //     alert(JSON.stringify(values, null, 2));
                                                    // }, 100)
                                                    this.extra_option_submit(product, values)

                                                }
                                            >

                                                {({ values, errors, touched }) => (
                                                    <Form>
                                                        <FieldArray
                                                            name="KeuzegerechtOptions"
                                                            render={arrayHelpers => (
                                                                <div>
                                                                    {values.KeuzegerechtOptions && values.KeuzegerechtOptions.length > 0 && (
                                                                        values.KeuzegerechtOptions.map((KeuzegerechtOption, index) => (
                                                                            <div key={product.id +"keuzegerecht"+ index}>
                                                                                <div className="product-extra-options-inside">


                                                                                    <label className="label-productoptions" htmlFor={`KeuzegerechtOptions.${index}.name`}>
                                                                                        {index>=product.MenuKeuzeGerechtOptions ? "Extra gerecht" : "Keuzegerecht"}:
                                                                                    </label>
                                                                                    <div className="keuzegerecht-with-minusbutton">
                                                                                        {index < product.MenuKeuzeGerechtOptions ?
                                                                                            <Field
                                                                                                name={`KeuzegerechtOptions.${index}.name`}
                                                                                                as="select"
                                                                                                className="my-select">
                                                                                                <option value=""></option>
                                                                                                {/*<option value="nasi">nasi</option>*/}
                                                                                                {/*<option value="bami">bami</option>*/}
                                                                                                {this.props.keuzegerechten_options.map((option, o_i) =>

                                                                                                    <option
                                                                                                        key={product.id + "keuzegerecht" + index + "" + o_i}
                                                                                                        value={option.naam}>{option.naam}</option>
                                                                                                )}
                                                                                            </Field>:
                                                                                            <Field
                                                                                                name={`KeuzegerechtOptions.${index}.name`}
                                                                                                as="select"
                                                                                                className="my-select">
                                                                                                <option value=""></option>
                                                                                                {/*<option value="nasi">nasi</option>*/}
                                                                                                {/*<option value="bami">bami</option>*/}
                                                                                                {this.props.kleinegerechten_options.map((option, o_i) =>

                                                                                                    <option
                                                                                                        key={product.id + "extragerecht" + index + "" + o_i}
                                                                                                        value={option.naam}>{option.naam}  (+€{option.price})</option>
                                                                                                )}
                                                                                            </Field>
                                                                                        }


                                                                                        {index>=product.MenuKeuzeGerechtOptions &&

                                                                                        <IconButton
                                                                                             size='small'
                                                                                            // type="button"
                                                                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                                                        >
                                                                                            <HighlightOffIcon/>
                                                                                        </IconButton>
                                                                                        }
                                                                                    </div>
                                                                                    {/*<button*/}
                                                                                    {/*    type="button"*/}
                                                                                    {/*    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list*/}
                                                                                    {/*  >*/}
                                                                                    {/*    -*/}
                                                                                    {/*  </button>*/}
                                                                                    {/*  <button*/}
                                                                                    {/*    type="button"*/}
                                                                                    {/*    onClick={() => arrayHelpers.insert(index, new Friend())} // insert an empty string at a position*/}
                                                                                    {/*  >*/}
                                                                                    {/*    +*/}
                                                                                    {/*  </button>*/}

                                                                                    {errors &&
                                                                                    errors.KeuzegerechtOptions &&
                                                                                    errors.KeuzegerechtOptions[index] &&
                                                                                    errors.KeuzegerechtOptions[index].name &&
                                                                                    touched &&
                                                                                    touched.KeuzegerechtOptions &&
                                                                                    touched.KeuzegerechtOptions[index] &&
                                                                                    touched.KeuzegerechtOptions[index].name && (
                                                                                        <div className="field-error">
                                                                                            {errors.KeuzegerechtOptions[index].name}
                                                                                        </div>
                                                                                    )}
                                                                                </div>


                                                                            </div>
                                                                        ))
                                                                    ) }


                                                                    {values.KeuzegerechtOptions && values.KeuzegerechtOptions.length > 0 && (
                                                                        <a className="a-extra-keuzegerechten" type="button" onClick={() => arrayHelpers.push(new KeuzegerechtOption())}>
                                                                            {/* show this when user has removed all friends from the list */}
                                                                            Extra keuzegerecht toevoegen
                                                                        </a>

                                                                    )}

                                                                    {/* {values.NasiOrBamiOptions && values.NasiOrBamiOptions.length > 0 && product.weekmenu &&(*/}
                                                                    {/*     // console.log("NASI BAMI TEST",product.weekmenu)*/}
                                                                    {/*    <a className="a-extra-keuzegerechten" type="button" onClick={() => arrayHelpers.push(new KeuzegerechtOption())}>*/}
                                                                    {/*        /!* show this when user has removed all friends from the list *!/*/}
                                                                    {/*        Klik hier nasi en bami te veranderen.*/}
                                                                    {/*    </a>*/}

                                                                    {/*)}*/}




                                                                    {values.NasiOrBamiOptions && values.NasiOrBamiOptions.length > 0 && (
                                                                        values.NasiOrBamiOptions.map((NasiOrBamiOption, index) => (
                                                                            <div key={product.id +"nasibami"+ index}>
                                                                                <div className="product-extra-options-inside">
                                                                                    <label className="label-productoptions" htmlFor={`NasiOrBamiOptions.${index}.name`}>
                                                                                        Nasi/bami:
                                                                                    </label>

                                                                                    <Field name={`NasiOrBamiOptions.${index}.name`} as="select" className="my-select">
                                                                                        <option value=""></option>
                                                                                        {/*<option value="nasi">nasi</option>*/}
                                                                                        {/*<option value="bami">bami</option>*/}
                                                                                        {this.props.nasi_bami_options.map((option, o_i)=>

                                                                                            <option key={product.id +"nasibami"+ index + "" +o_i} value={option.naam}>{option.price>0?option.naam+" (+€"+option.price+")":option.naam}</option>
                                                                                        )}
                                                                                    </Field>

                                                                                    {errors &&
                                                                                    errors.NasiOrBamiOptions &&
                                                                                    errors.NasiOrBamiOptions[index] &&
                                                                                    errors.NasiOrBamiOptions[index].name &&
                                                                                    touched &&
                                                                                    touched.NasiOrBamiOptions &&
                                                                                    touched.NasiOrBamiOptions[index] &&
                                                                                    touched.NasiOrBamiOptions[index].name && (
                                                                                        <div className="field-error">
                                                                                            {errors.NasiOrBamiOptions[index].name}
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                            </div>
                                                                        ))
                                                                    )}



                                                                    <div className="product-price">
                                                                        <div>

                                                                            {/*{JSON.stringify((values['NasiOrBamiOptions']), null, 2)}*/}
                                                                            {/*{JSON.stringify(this.props.nasi_bami_options, null, 2)}*/}
                                                                            {/*{calculateNasiBamiExtraCost(values['NasiOrBamiOptions'],this.props.nasi_bami_options)}*/}
                                                                            <button type="submit" className="btn btn-primary btn-sm button-extra-options" >
                                                                                € {(parseFloat(product.price)
                                                                                 +(calculateNasiBamiExtraCost(values['KeuzegerechtOptions'].slice(product.MenuKeuzeGerechtOptions),this.props.kleinegerechten_options))
                                                                                 +(calculateNasiBamiExtraCost(values['NasiOrBamiOptions'],this.props.nasi_bami_options))
                                                                                 ).toFixed(2)}
                                                                            </button>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            )}
                                                        />
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </Collapse>


                                }

                            </div>
                        </li>
                    )}
                </ul>


                <Snackbar open={this.state.open_toast} autoHideDuration={800} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} >
                        Toegevoegd!
                    </Alert>
                </Snackbar>

            </div>
        )
    }
}



