import React from "react";
/* We simply can use an array and loop and print each user */
const CateringPage = () => {
  return (
      <div>
              <div className="catering-banner" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/catering-0.jpg'})`,
                            backgroundRepeat: 'no-repeat',
                            // width:'200vh'
                            // backgroundSize: '100% 700px'
                        }}/>




      <div className="container2 container">
      <div className="row ">
        <div className="announcement border">
          Momenteel geen cateringservice!
            </div>
      </div>

          <div className="row">
                    <div className="col-sm-12">
                        <br/>
                        <h2 className="subtitle-center restaurant-red">Catering </h2>
                        <br/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">

                        <p>
                        Heeft u een feestje thuis of op het bedrijf met meer dan 20 personen, laat het ons dan weten.

                        Wij zorgen voor een heerlijk buffet, en bovendien leveren wij desgewenst alle borden en het bestek. Na het eten halen wij alles weer op.

                        Voor u heerlijk genieten, want wij doen de afwas voor u! Voor grotere aantallen maken wij een offerte op maat.</p>
                        <p>
                         <a href = {process.env.PUBLIC_URL + '/documents/CATERING.pdf'} target = "_blank" rel="noopener noreferrer">Klik hier voor onze catering menu</a> <br/>
                         </p>
                    </div>

                </div>
      </div>
           </div>
  );
};

export default CateringPage;