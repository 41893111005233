
import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {Link, Redirect} from "react-router-dom";
// import {PersistFormikValues} from "formik-persist-values";


//animation



class localBuffetPage extends React.Component{

    constructor(){
        super();
        this.state = {
            redirect_to_login: false,
            server_error: false,
            logged_in: false,
            redirect_to_successpage: false,
            error_message: "",
            button_disabled: false,
        }
    }






    componentDidMount() {
        let token = localStorage.getItem('localOrder-token')
        if (token === null) { // make new device id
            localStorage.setItem('localOrder-token', "")
        }


        let data ;
        let loggedIn;

        axios.post('/local-order-api/is-table-logged-in/', {
            token: localStorage.getItem('localOrder-token')

        })
            .then((response) => {
                data = response.data;
                loggedIn = data["loggedin"]
                // console.log('logged in: ', data["login-accepted"])

                if (loggedIn) {
                    this.setState({
                        logged_in:true,
                    })

                } else {
                    this.setState({
                        logged_in:false,
                        redirect_to_login:true,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });


    }




    render() {
       const buffet_warm = [{fullname:"71 Chilisoep", shortname:"71 Chilisoep"},
            {fullname:"71a Chilisoep met lente-ui", shortname:"71a Chilisoep+lentui"},
            {fullname:"72 Tomaten soep", shortname:"72 Tomaten soep"},
            {fullname:"72a Tomaten soep met lente-ui", shortname:"72a Tomat soep+lentui"},
            {fullname:"73 Nasi", shortname:"73 Nasi"},
            {fullname:"74 Bami", shortname:"74 Bami"},
            {fullname:"75 Mihoen", shortname:"75 Mihoen"},
            {fullname:"76 Friet", shortname:"76 Friet"},
            {fullname:"76a Mayonaise", shortname:"76a Mayonaise"},
            {fullname:"76b Ketchup", shortname:"76b Ketchup"},
            {fullname:"76c Currysaus", shortname:"76c Currysaus"},
            {fullname:"76d Chilisaus", shortname:"76d Chilisaus"},
            {fullname:"77 Gebakken aardappelen", shortname:"77 Geb aardappel"},
            {fullname:"78 Frikandel", shortname:"78 Frikandel"},
            {fullname:"79 Mini loempia", shortname:"79 Mini loempia"},
            {fullname:"80 Kipfilet met uien", shortname:"80 Kipfilet ui"},
            {fullname:"81 Kon po varkenshaas", shortname:"81 Kon po varken"},
            {fullname:"82 Gebakken ananas", shortname:"82 Geb ananas"},
            {fullname:"83 Gebakken garnalen", shortname:"83 Geb garnalen"},
            {fullname:"84 Kip lasiang saus", shortname:"84 Kip lasiang"},
            {fullname:"85 Geroosterd spek", shortname:"85 Geroost spek"},
            {fullname:"86 Gebakken inktvisring", shortname:"86 Geb inktvisring"},
            {fullname:"87 Kip kerry", shortname:"87 Kip kerry"},
            {fullname:"88 Saté", shortname:"88 Sate"},
            {fullname:"88a Satésaus", shortname:"88a Satesaus"},
            {fullname:"89 Ossehaas knoflooksaus", shortname:"89 Ossehaas knoflook"},
            {fullname:"90 Spareribs", shortname:"90 Spareribs"},
            {fullname:"91 Kipfilet vruchtensaus", shortname:"91 Kip vruchten"},
            {fullname:"92 Mix groente", shortname:"92 Mix groente"},
            {fullname:"93 Vleesblokjes ketjapsaus", shortname:"93 Vleesblok ketjap"},
            {fullname:"94 Kipfilet champignons", shortname:"94 Kip champignon"},
            {fullname:"95 Foe yong hai", shortname:"95 Foe yong hai"},
            {fullname:"96 Babi pangang", shortname:"96 Babi pangang"},
            {fullname:"97 Baarsfilet zoetz.saus", shortname:"97 Baars zoetz saus"},
            {fullname:"201 Witte rijst", shortname:"201 Witte rijst"},
        ]

        const buffet_koud = [    {fullname:"202 Cassave kroepoek", shortname:"202 Cassave kroepoek"},
            {fullname:"98 Komkommer", shortname:"98 Komkommer"},
            {fullname:"99 Ijsbergsalade", shortname:"99 Ijsbergsalade"},
            {fullname:"101 Aziatische salade", shortname:"101 Aziatische salad"},
            {fullname:"102 Olijven", shortname:"102 Olijven"},
            {fullname:"103 Japanse zeewier", shortname:"103 Japanse zeewier"},
            {fullname:"104 Augurken", shortname:"104 Augurken"},
            {fullname:"105 Atjar", shortname:"105 Atjar"},
            {fullname:"106 Witte kool salade", shortname:"106 Witte kool salad"},
            {fullname:"107 Aardappelsalade", shortname:"107 Aardappelsalad"},
        ]

        const buffet_sushi = [ {fullname:"108 Nigri zalm", shortname:"108 Nigri zalm"},
            {fullname:"109 Nigri garnaal", shortname:"109 Nigri garnaal"},
            {fullname:"110 Nigri krabstick", shortname:"110 Nigri krabstic"},
            {fullname:"111 Maki zalmrol", shortname:"111 Maki zalmrol"},
            {fullname:"112 Maki kiprol", shortname:"112 Maki kiprol"},
            {fullname:"113 Maki komkommerrol", shortname:"113 Maki komkommer"},
            {fullname:"114 Maki tonijnrol", shortname:"114 Maki tonijn"},
            {fullname:"115 Maki koolsalade garnaal", shortname:"115 Maki kool garn"},
            {fullname:"116 Maki komkommer sesam", shortname:"116 Maki komkom se"},
            {fullname:"117 Sushirol avocado geb. uitjes", shortname:"117 Sushi avocado"},
            {fullname:"118 Sushirol groene masago", shortname:"118 Sushi groen"},
            {fullname:"119 Sushirol oranje masago", shortname:"119 Sushi oranje"},
            {fullname:"120 Sushirol rode masago", shortname:"120 Sushi rood"},
            {fullname:"120a Sojasaus voor sushi", shortname:"120a Sojasaus"},
            {fullname:"120b Gember voor sushi", shortname:"120b Gember"},
            {fullname:"120c Wasabi voor sushi", shortname:"120c Wasabi"}]

        const korte_sushi = []
        for (const pair of buffet_sushi) {
            korte_sushi.push(pair.shortname)
            //key will be -> 'id'
            //dictionary[key] -> 'value'
        }


        if (this.state.redirect_to_login) {
            // console.log("no dataa");
            return <Redirect to='/bestel/login'
            />;
        }
        if (this.state.redirect_to_successpage) { //success bestellen!
            return <Redirect  to={{
                pathname:'/bestel/succes',

            }}
            />;
        }
        if (this.state.logged_in) {
            return (
                <div className="checkout-container container">
                    <div className="checkout-row row">
                        <div className="col-sm-12">
                             <Link to="/bestel" className="btn btn-outline-secondary cart-button">

                                    Klik hier om terug naar de bestel hoofdpagina te gaan.

                               </Link>
                            <br/>
                            <br/>
                            <h2 className="subtitle-center restaurant-red">Buffet</h2>

                            <div className="" id="form-wrapper">
                                {/*<Link to="/" className="btn btn-outline-dark">&#x2190; Go Back</Link>*/}
                                <Formik
                                    initialValues={{naam: '', hoeveelheid: '', buffetkoud:[], buffetwarm:[]}}

                                    validationSchema={Yup.object({
                                        naam: Yup.string()
                                            .max(15, 'Te veel karakters!'),

                                        // woksaus: Yup.string()
                                        //     .max(100, 'Te veel karakters!')
                                        //     .required('Verplicht'),
                                        // // hoeveelheid: Yup.string().required("A radio option is required"),



                                    })}

                                    onSubmit={async (values, {setSubmitting}) => {
                                        if (!this.state.button_disabled) {
                                            this.setState({button_disabled: true});

                                            setSubmitting(true);
                                            // alert(JSON.stringify(values, null, 2));

                                            let orderedSushi = []
                                            for(var key in values) {
                                                if(korte_sushi.includes(key)) {
                                                    // console.log(key)
                                                    if (values[key] != 0) {
                                                        orderedSushi.push({[key]: values[key]})
                                                    }
                                                }

                                            }

                                            axios.post('/local-order-api/process-order/', {
                                                token: localStorage.getItem('localOrder-token'),
                                                naam: values["naam"],
                                                woksaus: "",
                                                hoeveelheid: values["hoeveelheid"],
                                                vlees: [],
                                                groente: [],
                                                buffetWarm: values["buffetwarm"],
                                                buffetKoud: values["buffetkoud"],
                                                buffetSushi: orderedSushi,
                                                foodtype: "BUFFET",
                                                nagerechten: [],
                                                drinken: [],


                                            })
                                                .then((response) => {
                                                    let loggedIn;
                                                    let data;

                                                    console.log("wok is processed")
                                                    data = response.data;
                                                    loggedIn = data["loggedin"]


                                                    if (loggedIn) {
                                                        this.setState({
                                                            logged_in: true,
                                                        })

                                                        //next check if input was correct
                                                        if (response.data['input-correct']) {

                                                            //everything is good from this point onwards.
                                                            this.setState({
                                                                server_error: false,
                                                                redirect_to_successpage: true,

                                                            })

                                                            // //set token in localdevice.
                                                            // localStorage.setItem('localOrder-token', response.data['token'])
                                                        } else {
                                                            this.setState({server_error: true, error_message:response.data['error-message'], button_disabled: false});
                                                        }
                                                        // not logged in
                                                    } else {
                                                        this.setState({
                                                            logged_in: false,
                                                            redirect_to_login: true,
                                                        })
                                                    }


                                                    //with response, update the cart.

                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    setSubmitting(false);
                                                    this.setState({button_disabled: false});
                                                });
                                        }
                                    }}
                                >{({isSubmitting}) => (
                                    <Form className="checkoutpage-form">
                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="naam"><h4>Uw naam:</h4></label>
                                            <Field className="form-control" name="naam" type="text"/>
                                            <ErrorMessage name="naam">
                                                {msg => <div className="field-error">{msg}</div>}
                                            </ErrorMessage>
                                        </div>
                                        <br/>

                                        <div id="radioGroup"><label className="font-weight-bold"  htmlFor="hoeveelheid"><h4>Hoe vol wilt u uw bord?</h4></label></div>
                                        <div className="form-check " role="group" aria-labelledby="radioGroup">
                                            <label className="form-check-label local-choices">
                                                <Field  className="form-check-input local-checkbox" type="radio" name="hoeveelheid" value="klein" />
                                                Klein
                                            </label>
                                            <label className="form-check-label local-choices">
                                                <Field className="form-check-input local-checkbox" type="radio" name="hoeveelheid" value="medium" />
                                                Medium
                                            </label>
                                            <label className="form-check-label local-choices">
                                                <Field className="form-check-input local-checkbox" type="radio" name="hoeveelheid" value="groot" />
                                                Groot
                                            </label>
                                        </div>

                                        <br/>

                                        <div className="form-group">

                                              <label className="font-weight-bold"><h4>Buffet</h4></label>
                                            <div id="checkbox-group"><label className="font-weight-bold" htmlFor="buffetwarm">Warme gerechten:</label></div>
                                            <div className="form-check "  role="group" aria-labelledby="checkbox-group">

                                                {buffet_warm.map(product=>
                                                    <label className="form-check-label local-choices">
                                                        <Field className="form-check-input local-checkbox" type="checkbox" name="buffetwarm" value={product.shortname} />
                                                        {product.fullname}
                                                    </label>)}
                                            </div>

                                            <br/>

                                            <div id="checkbox-group"><label className="font-weight-bold"  htmlFor="buffetkoud">Koude gerechten:</label></div>
                                            <div className="form-check "  role="group" aria-labelledby="checkbox-group">

                                                {buffet_koud.map(product=>
                                                    <label className="form-check-label local-choices">
                                                        <Field className="form-check-input local-checkbox" type="checkbox" name="buffetkoud" value={product.shortname} />
                                                        {product.fullname}
                                                    </label>)}
                                            </div>

                                            <br/>

                                            <div id="checkbox-group"><label className="font-weight-bold"  htmlFor="buffetsushi">Sushi:</label></div>
                                            <div className="form-check "  role="group" aria-labelledby="checkbox-group">

                                                {buffet_sushi.map(product=>
                                                   <div className="select-10-numbers">
                                                        <Field className="form-control select-10-numbers-field" name={product.shortname} as="select">

                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                        </Field>
                                                        <label className="form-check-label local-choices">{product.fullname} </label>
                                                    </div>)}
                                            </div>






                                            <ErrorMessage name="radioGroup" >
                                                { msg => <div className="field-error">{msg}</div> }
                                            </ErrorMessage>


                                            <div>
                                                {this.state.server_error ?
                                                    <button className="btn btn-danger mobile-local-bestel-button" type="submit"
                                                            disabled={this.state.button_disabled}>{this.state.error_message} <br/> Daarna kunt u hier weer klikken om te bestellen.
                                                </button> :
                                                    <button className="btn btn-primary mobile-local-bestel-button" type="submit"
                                                        disabled={this.state.button_disabled}>Klik hier om het direct te bestellen!
                                                </button> }
                                            </div>


                                        </div>
                                        {/*<p>test: naam: {this.state.name}, email: {this.state.email}, phone: {this.state.phone}, payment: {this.state.paymentoption}, tijdstip: {this.state.tijdstip} </p>*/}
                                        {/*<PersistFormikValues  name="form-data" storage='sessionStorage' persistInvalid={true} />*/}
                                    </Form>
                                )}
                                </Formik>

                            </div>


                            {/*// <div className="login-wrapper">*/}
                            {/*//     <form>*/}
                            {/*//         <label>*/}
                            {/*//             <p>Tafel nummer:</p>*/}
                            {/*//             <input type="text" />*/}
                            {/*//         </label>*/}
                            {/*//         <label>*/}
                            {/*//             <p>Wachtwoord:</p>*/}
                            {/*//             <input type="password" />*/}
                            {/*//         </label>*/}
                            {/*//         <div>*/}
                            {/*//             <button type="submit">Submit</button>*/}
                            {/*//         </div>*/}
                            {/*//     </form>*/}
                            {/*// </div>*/}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div><p>Loading...</p></div>
                </div>
            )
        }
    }
}

export default localBuffetPage;


