import React from "react";
import {Link} from "react-router-dom";
/* We simply can use an array and loop and print each user */
const RestaurantPage = () => {
  return (
      <div>
           <div className="restaurant-banner" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/restaurant-banner.jpg'})`,
                            backgroundRepeat: 'no-repeat',
                            // width:'200vh'
                            // backgroundSize: '100% 700px'
                        }}/>



      <div className="container2 container">
     <div className="row">
                    <div className="col-sm-12">

                        <h2 className="subtitle-center restaurant-red">Restaurant</h2>
                        <br/>
                    </div>
                </div>

                <div className="row">

                    <div className="col-sm-7">

                        <p>U kunt bij ons vanzelfsprekend terecht voor de bekende Chinese gerechten.
                        Wij hebben een zeer uitgebreide kaart met zowel specialiteiten uit de verschillende streken, als wat meer bekende algemene gerechten. Een rijsttafel, of enkel een gerecht, of zelfs een verrassing van de Chef-Kok, bij ons zult u genieten van een lekkere maaltijd.
                            Kom eens langs om uzelf te laten verwennen met wat de kok voor u in petto heeft!</p>

                         <div className="reservebutton" >
                                <Link style={{"margin-top":"20px"}} to="/reserveren" className="btn btn-outline-danger cart-button">
                                    <div>Reserveren
                                    </div></Link>
                            </div>

                    </div>

                     <div className="col-sm-5">
                         <img className="center-images image-fit-container " src={process.env.PUBLIC_URL + '/images/restaurant-2.jpg'} alt="Image of the restaurant"/>
                    </div>
                    {/*<div className="col-sm-7">*/}
                    {/*    <img className="center-images image-fit-container" src={process.env.PUBLIC_URL + '/images/restaurant-3.jpg'} alt="Image of the restaurant"/>*/}

                    {/*</div>*/}


                </div>
      </div>
            </div>
  );
};

export default RestaurantPage;