import React from "react";
/* We simply can use an array and loop and print each user */
/*which guide?? https://www.digitalocean.com/community/tutorials/how-to-integrate-the-google-maps-api-into-react-applications
https://dev.to/jessicabetts/how-to-use-google-maps-api-and-react-js-26c2
https://blog.logrocket.com/a-practical-guide-to-integrating-google-maps-in-react/
 */

import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%'
};


export class ContactPage extends React.Component{

    constructor(){
         super();
         this.state = {
            showingInfoWindow: false,  // Hides or shows the InfoWindow
            activeMarker: {},          // Shows the active marker upon click
            selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
         };
    }

    onMarkerClick = (props, marker, e) =>
        this.setState({
          selectedPlace: props,
          activeMarker: marker,
          showingInfoWindow: true
    });

    onClose = props => {
        if (this.state.showingInfoWindow) {
          this.setState({
            showingInfoWindow: false,
            activeMarker: null
          });
        }
    };


  render(){

      return (
          <div>


               <div className="contact-banner" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/restaurant-outside.jpg'})`,
                            backgroundRepeat: 'no-repeat',
                            // width:'200vh'
                            // backgroundSize: '100% 700px'
                        }}/>


        <div className="container2 container">

            <div className="row">
                    <div className="col-sm-12">
                        <br/>
                        <h2 className="subtitle-center restaurant-red">Contact & Openingstijden </h2>
                        <br/>
                    </div>
                </div>
          <div className="row box border">

                <div className="col-sm-6">
                    <div className="google-map">
                     <Map
                        google={this.props.google}
                        zoom={15}
                        style={mapStyles}
                        initialCenter={
                          {
                            lat: 51.331311626064156,
                            lng: 6.091044299441478
                          }
                        }
                     >
                         <Marker
                          onClick={this.onMarkerClick}
                          name={'Wokrestaurant, Peking Garden\n'}
                        />
                        <InfoWindow
                          marker={this.state.activeMarker}
                          visible={this.state.showingInfoWindow}
                          onClose={this.onClose}
                        >
                          <div>
                            <h4>{this.state.selectedPlace.name}</h4>
                          </div>
                        </InfoWindow>


                     </Map>
                    </div>
                    <br/>

                <p>Wokrestaurant Peking Garden ligt in Baarlo aan de Napoleonsbaan (Rijksweg N273) tussen Venlo en Kessel.
                    Vanuit Venlo, Tegelen en Steyl kunt u ons ook goed bereiken via het veerpont Steyl-Baarlo.</p></div>
                <div className="col-sm-6">

                    <div>
                        <b>Contact</b>
                        <br/>
                        Wokrestaurant
                        <br/>
                        Peking Garden
                        <br/>
                        Napoleonsbaan Noord 46
                                            <br/>
                        5991 NW Baarlo
                                            <br/>
                        Tel.: 077-477 14 14 <br/>

                        info@wokbaarlo.nl
                        <br/>
                        <br/>
                    </div>


                    <div>

                          <b>Wok openingstijden</b>
                        <br/>
                        Maandag t/m zaterdag: 17.00 - 22.00
                        <br/>
                        Zondag: 16.00 - 22.00
                        <br/>
                        <em>Dinsdagen gesloten</em><br/>
                        <b>Afhalen openingstijden</b>
                        <br/>
                        Maandag t/m zaterdag:	16.00 - 21.30
                        <br/>
                        Zondag:	12.00 - 21.30
                        <br/>
                        <em>Dinsdagen gesloten</em><br/>
                         <br/>
                        Reserveren is aan te raden!
    Wij raden u aan om tijdig te reserveren, zeker wanneer u in het weekend bij ons wilt komen eten.

                    </div>




                </div>

          </div>
        </div>
              </div>

              );
  }
}




export default GoogleApiWrapper({
  apiKey: 'AIzaSyCsscAji88bSP0n-zbPzkstl3zMbc4LlfM'
})(ContactPage);