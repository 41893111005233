import React from "react";
/* We simply can use an array and loop and print each user */
const CookieVerklaringPage = () => {
  return (
    <div className="container">
      <h1>Cookie Verklaring Voor Wok Baarlo</h1>

<p>Dit is het cookie verklaring voor Wok Baarlo, toegankelijk via wokbaarlo.nl</p>

<p><strong>Wat Zijn Cookies</strong></p>

<p>Zoals gebruikelijk bij bijna alle professionele websites, gebruikt deze site cookies, dit zijn kleine bestanden die naar uw computer worden gedownload om uw ervaring te verbeteren. Deze pagina beschrijft welke informatie ze verzamelen, hoe we deze gebruiken en waarom we deze cookies soms moeten opslaan. We zullen ook delen hoe u kunt voorkomen dat deze cookies worden opgeslagen, maar dit kan bepaalde elementen van de functionaliteit van de site downgraden of 'breken'.</p>

<p><strong>Hoe We Cookies Gebruiken</strong></p>

        <p>We gebruiken cookies om verschillende redenen die hieronder worden beschreven. Helaas zijn er in de meeste gevallen geen industriestandaardopties voor het uitschakelen van cookies zonder de functionaliteit en functies die ze aan deze site toevoegen volledig uit te schakelen. Het wordt aanbevolen om alle cookies aan te laten staan als u niet zeker weet of u ze nodig heeft of niet als ze worden gebruikt om een dienst te leveren die u gebruikt.</p>

<p><strong>Cookies Uitschakelen</strong></p>

<p>U kunt het plaatsen van cookies voorkomen door de instellingen in uw browser aan te passen (zie de Help van uw browser hoe u dit kunt doen). Houd er rekening mee dat het uitschakelen van cookies de functionaliteit van deze en vele andere websites die u bezoekt, beïnvloedt. Het uitschakelen van cookies resulteert meestal ook in het uitschakelen van bepaalde functionaliteit en functies van deze site. Daarom is het aan te raden om cookies niet uit te schakelen.</p>


        <p><strong>De Cookies Die We Instellen</strong></p>

<ul>




<li>
    <p>- Bestelling-verwerking
  gerelateerde cookies</p>
   <p>Deze site biedt e-commerce- of betalingsfaciliteiten en sommige cookies zijn essentieel om ervoor te zorgen dat uw bestelling tussen pagina's wordt onthouden, zodat we deze goed kunnen verwerken.</p>

</li>




</ul>

<p><strong>Cookies van derden</strong></p>

<p>We gebruiken geen cookies van derde partijen.</p>




    </div>
  );
};

export default CookieVerklaringPage;