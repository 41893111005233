import React from "react";
import {Link, Redirect} from "react-router-dom";

class localSuccessPage extends React.Component {

    constructor(){
        super();
        this.state = {

            // redirect: false, //redirect back to shopping if no data received
        }


    }

    componentDidMount() {


    }

    render() {




        return (
            <div>


                <div className="container">
                <h2 className="subtitle-center restaurant-red">Uw bestelling was succesvol!</h2>
                    <br/>
                    <p className="center-columns">We werken er hard aan om u bestelling zo snel mogelijk klaar te maken.</p>
                    <div className="row center-columns ">
                    <div className=" col-sm-4 afhaalbutton">

                                <Link to="/bestel" className="btn btn-info cart-button">

                                    <div>Klik hier om terug naar de home pagina te gaan

                                    </div></Link>

                     </div>
                        </div>

                </div>
            </div>


        )

    }




}

export default localSuccessPage;