import React, {Component} from 'react';


export default class CartOfSuccesspage extends Component {
    render() {
        const {cartItems} =  this.props;
        return (
            <div className="cart">


                <div className="cart-body">

                            <ul className="cartItems">
                                {cartItems.map(item => (





                                    <li key={item.id}>
                                        <div className="cartItem">
                                            <div className="cartItem-row">
                                                {/*{console.log("cartitem", item)}*/}
                                                <div className="cartItem-row-leftside">
                                                    <div className="cartItem-quantity-name">
                                                        <span className="cartItem-quantity">{item.quantity}x</span>
                                                        <span className="cartItem-name">{item.product.name}</span>
                                                    </div>

                                                     <div className="cart-extra-options">
                                                        <ul>
                                                            {item.keuzemenu_options.map((keuzemenu_option, index) => (
                                                                    <li key = {keuzemenu_option.id}>
                                                                        {" "+keuzemenu_option.quantity+ "x "+keuzemenu_option.product.naam}
                                                                    </li>
                                                                )
                                                            )}
                                                            {item.nasi_bami_options.map((nasibami_option, index) => (
                                                                    <li key = {nasibami_option.id}>
                                                                        {" "+nasibami_option.quantity+ "x "+nasibami_option.product.naam}
                                                                    </li>
                                                                )
                                                            )}
                                                             {item.kleinegerechten_options.length!=0 && <b>extra:</b>}
                                                           {item.kleinegerechten_options.map((kleingerecht_option, index) => (
                                                                    <li key = {kleingerecht_option.id}>
                                                                        {" "+kleingerecht_option.quantity+ "x "+kleingerecht_option.product.naam}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="cartItem-row-rightside">
                                                    <span className="cartItem-price">€{" "+(item.total_cost_without_quantity * item.quantity).toFixed(2)}</span>
                                                </div>

                                            </div>

                                            {/*<hr />*/}
                                        </div>
                                    </li>

                                ))}
                            </ul>

                    {/*</div>*/}
                    {cartItems.length!==0 &&(


                        <div className="total">
                            <div className="cart-totalPrice">
                                <span>
                                    <strong>Totaal:</strong>
                                </span>
                                {/*cartItems.reduce((a,c) => a + c.price*c.count, 0)*/}
                                <span>
                                    <strong>
                                    €{" "+this.props.total_cost.toFixed(2)}
                                    </strong>
                                </span>
                            </div>
                            {/*<Link to="/checkout"><button className="button primary">Proceed</button></Link>*/}



                        </div>


                    )}


                </div>
            </div>

        );
    }
}

