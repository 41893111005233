
import React from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
//import '../reactreduxtutorial.css';
// import {v4 as uuidv4} from 'uuid';
import Cart from "../../components/Cart";

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "yup-phone";
import { PersistFormikValues } from 'formik-persist-values';

import {
    Redirect
} from "react-router-dom";

// wait function to test slow connections.
// const sleep = (ms) => new Promise((r) => setTimeout(r, ms));


class Checkoutpage extends React.Component{

    constructor(){
        super();
        this.state = {
            //cart item stuff:
            cartItems: [],
            total_cost: 0,
            total_quantity: 0,

            //form stuff
            // name: "",
            // email: "",
            // phone: "",
            // paymentoption: "",
            // tijdstip: "",

            redirect: false,
            server_error: false,

            error_message: "",
            button_disabled: false,


            // when redirect is true, submit this:
            customer_name: "",
            customer_email: "",
            customer_phone: "",
            tijdstip: "",
            final_total_cost: "",
            orderID: "",


            //time
            timesUpdated: false,
            timeBuffer: 12,
            off_1600_1630: false,
            off_1630_1700: false,
            off_1700_1730: false,
            off_1730_1800: false,
            off_1800_1830: false,
            off_1830_1900: false
        }
    }




    componentDidMount() {

        //deviceid.
        //deviceid.
        let deviceId = localStorage.getItem('device-id')
        if (deviceId === null) { //let device id be empty.
            deviceId = ""
            localStorage.setItem('device-id', deviceId)
        }
        this.getCart()
        this.loadTimes()
    }

    // returns cart from django
    getCart = () => {
        axios.post('/api/cart/',{

            device: localStorage.getItem('device-id'),

        })
            .then((response)=> {
                //   console.log("cartt")
                // console.log(response);
                //   //console.log(response.data[0]);
                //   console.log("endcartt")
                this.setState({cartItems: response.data['items']})
                this.setState({total_cost: response.data['total_cost']})
                this.setState({total_quantity: response.data['total_quantity']})
            })
            .catch((error)=> {
                // console.log(error);
            });
    }

    //action is 'add', 'decrease', or 'delete'.
    //orderitemID takes precedense over all other options. as orderitem id is unique. If no given: (="")
    updateCart = (productID, action, nasi_bami_options, keuzegerechten_options, kleinegerechten_options, orderitemID) => {
        // const cartItems = this.state.cartItems.slice();
        // let alreadyInCart = false;
        // cartItems.forEach(item =>{
        //     if(item.id === product.id){
        //         item.count++;
        //         alreadyInCart = true;
        //
        //     }
        // });
        // if(!alreadyInCart){
        //     cartItems.push({...product, count: 1});
        // }

        axios.post('/api/update-item/', {
            productID: productID,
            action: action,
            NasiOrBami: nasi_bami_options,
            keuzegerechten: keuzegerechten_options,
            kleinegerechten: kleinegerechten_options,
            orderitemID:orderitemID,
            device: localStorage.getItem('device-id')

        })
            .then((response) => {

                if (response.data['newdeviceid'] !== ""){
                    localStorage.setItem('device-id', response.data['newdeviceid'])
                }


                // console.log(response)
                // console.log(response.data['items']);
                //const cartItems =
                this.setState({cartItems: response.data['items']})
                this.setState({total_cost: response.data['total_cost']})
                this.setState({total_quantity: response.data['total_quantity']})
                //with response, update the cart.
            })
            .catch((error) => {
                // console.log(error);
            });


        // axios.get('http://localhost:8000/api/product-list/')
        // .then(response => {
        //     data = response.data;
        //     console.log('data:', data)
        //     this.setState({
        //         data : data,
        //         products : data,
        //     });
        // })
        // .catch(err => {})


        //this.getCart();

    }



    // //process Order
    // mySubmitHandler = (event) => {
    //     event.preventDefault();
    //     // let age = this.state.age;
    //     // if (!Number(age)) {
    //     //   alert("Your age must be a number");
    //     // }
    //
    //     axios.post('http://localhost:8000/api/process-order/',{
    //         name: this.state.name,
    //         email: this.state.email,
    //         phone: this.state.phone,
    //         paymentoption: this.state.paymentoption,
    //         tijdstip: this.state.tijdstip,
    //         device:localStorage.getItem('device-id'),
    //         total: this.state.total_cost,
    //
    //     })
    //     .then( (response)=>{
    //
    //               // console.log(response)
    //         // console.log(response.data['items']);
    //         //const cartItems =
    //           console.log("order is processed")
    //     //with response, update the cart.
    //     })
    //     .catch( (error)=> {
    //         console.log(error);
    //     });
    // }
    // myChangeHandler = (event) => {
    //     let nam = event.target.name;
    //     let val = event.target.value;
    //     this.setState({[nam]: val});
    // }

    // returns cart from django
    loadTimes = () => {
        axios.get('/api/available-times/',{

        })
            .then((response)=> {
                //   console.log("cartt")
                // console.log(response);
                //   //console.log(response.data[0]);
                //   console.log("endcartt")
                this.setState({timesUpdated: true,
                    timeBuffer: response.data['timebuffer'],
                    off_1600_1630: response.data['1600_1630'],
                    off_1630_1700: response.data['1630_1700'],
                    off_1700_1730: response.data['1700_1730'],
                    off_1730_1800: response.data['1730_1800'],
                    off_1800_1830: response.data['1800_1830'],
                    off_1830_1900: response.data['1830_1900']})
            })
            .catch((error)=> {
                // console.log(error);
            });
    }


    render(){
        const hours = ["16","17","18","19","20"];
        const minutes = ["00","05","10","15","20","25","30","35","40","45","50","55"];
        let time = new Date();
        if (this.state.timesUpdated) {
            time.setMinutes(time.getMinutes() + this.state.timeBuffer);
        }
        if (this.state.redirect) {
            return <Redirect  to={{
                pathname:'/online-afhalen/success',
                state: {  customer_name: this.state.customer_name,
                    customer_email: this.state.customer_email,
                    customer_phone: this.state.customer_phone,
                    tijdstip: this.state.tijdstip,
                    final_total_cost: this.state.final_total_cost,
                    cartItems: this.state.cartItems,
                    orderID: this.state.orderID}
            }}
            />;
        }
        return (
            <div>

                <div className="afhalen-banner" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/afhalen-banner-1.jpg'})`,
                    backgroundRepeat: 'no-repeat',
                    // width:'200vh'
                    // backgroundSize: '100% 700px'
                }}/>
                <div className="checkout-container container">
                    <div className="row">
                        <div style={{"marginBottom":"25px"}} className="announcement border">
                            <div className=" col-sm-12">

                                <div>

                                    <b>Welkom bij onze online afhaal service. Let op, u kunt alleen voor VANDAAG bezorgen. Ook is dit geen bezorg service, u moet nog steeds zelf komen ophalen.</b><br/>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="checkout-row row">
                        <div className="col-lg-6">


                            <div className="" id="form-wrapper">
                                {/*<Link to="/" className="btn btn-outline-dark">&#x2190; Go Back</Link>*/}
                                <Formik
                                    initialValues={{ name: '', email: '', phone: '',  tijdstip: ''}}

                                    validationSchema={Yup.object({
                                        name: Yup.string()
                                            .max(20, 'Gebruik a.u.b. 20 letters of minder')
                                            .required('Verplicht'),

                                        email: Yup.string().email('Ongeldig e-mailadres').required('Verplicht'),
                                        phone:  Yup.string()

                                            .required('Verplicht').phone("NL", true, 'Ongeldig nummer'),
                                        tijdstip: Yup.string().required('Verplicht'),



                                    })}

                                    onSubmit={async (values, {setSubmitting}) => {

                                        if (!this.state.button_disabled) {
                                            this.setState({button_disabled: true});


                                            window.grecaptcha.ready(() => {
                                                window.grecaptcha.execute('6Le2MiEbAAAAAGUQk5whRT9Umi1q_ECftCZ_SuZK', {action: 'afhaalcheckout'}).then((token) => {
                                                    setSubmitting(true);
                                                    // await sleep(500); make it slow
                                                    axios.post('/api/process-order/', {
                                                        name: values["name"],
                                                        email: values["email"],
                                                        phone: values["phone"],
                                                        tijdstip: values["tijdstip"],
                                                        device: localStorage.getItem('device-id'),
                                                        total: this.state.total_cost,
                                                        captchaToken: token,

                                                    })
                                                        .then((response) => {

                                                            // console.log(response)
                                                            // console.log(response.data['items']);
                                                            //const cartItems =
                                                            // console.log("order is processed")
                                                            // console.log(response.data['processed'])

                                                            if (response.data['processed']) {
                                                                this.setState({
                                                                    server_error: false,
                                                                    redirect: true,
                                                                    customer_name: response.data['customer_name'],
                                                                    customer_email: response.data['customer_email'],
                                                                    customer_phone: response.data['customer_phone'],
                                                                    tijdstip: response.data['tijdstip'],
                                                                    final_total_cost: response.data['final_total_cost'],
                                                                    orderID: response.data['orderID']

                                                                })
                                                                // this.setState({})
                                                            } else {
                                                                this.setState({
                                                                    server_error: true,
                                                                    error_message: response.data['errormsg']
                                                                })
                                                                this.setState({button_disabled: false});
                                                            }
                                                            //with response, update the cart.


                                                        })
                                                        .catch((error) => {
                                                            // console.log(error);
                                                            setSubmitting(false);
                                                            this.setState({button_disabled: false});
                                                        });

                                                });
                                            });
                                        }
                                    }}
                                >{({ isSubmitting }) => (
                                    <Form className="checkoutpage-form">
                                        <div className="form-group">
                                            <label className="font-weight-bold" htmlFor="name">Naam</label>
                                            <Field className="form-control" name="name" type="text" />
                                            <ErrorMessage name="name">
                                                { msg => <div className="field-error">{msg}</div> }
                                            </ErrorMessage>
                                        </div>

                                        <div className="form-group">
                                            <label className="font-weight-bold"  htmlFor="email">E-mailadres</label>
                                            <Field className="form-control" name="email" type="text" />
                                            <ErrorMessage name="email" >
                                                { msg => <div className="field-error">{msg}</div> }
                                            </ErrorMessage>
                                        </div>

                                        <div className="form-group">
                                            <label className="font-weight-bold"  htmlFor="phone">Telefoonnummer</label>
                                            <Field className="form-control" name="phone" type="text" />
                                            <ErrorMessage name="phone" >
                                                { msg => <div className="field-error">{msg}</div> }
                                            </ErrorMessage>
                                        </div>

                                        {/*<p>TEST{String(this.state.off_1600_1630)}</p>*/}


                                        <div className="form-group">
                                            <label className="font-weight-bold"  htmlFor="tijdstip">Tijdstip</label>
                                            <Field name="tijdstip" as="select" className="form-control my-select">
                                                {/*<option value="">{new Date().getHours()+":"+new Date().getMinutes()}</option>*/}
                                                <option value=""></option>
                                                {/*<option value="00.00">test-time</option>*/}

                                                {this.state.timesUpdated && hours.map((hour) =>
                                                    hour >= time.getHours() &&
                                                    minutes.map(min =>
                                                        ((hour == time.getHours() && min > time.getMinutes())
                                                            || (hour>time.getHours())) &&
                                                        //if 16:00 "00","05","10","15","20","25",
                                                        (!(this.state.off_1600_1630 && (hour == "16" && min < 30))) &&
                                                        (!(this.state.off_1630_1700 && (hour == "16" && min > 29))) &&
                                                        (!(this.state.off_1700_1730 && (hour == "17" && min < 30))) &&
                                                        (!(this.state.off_1730_1800 && (hour == "17" && min > 29))) &&
                                                        (!(this.state.off_1800_1830 && (hour == "18" && min < 30))) &&
                                                        (!(this.state.off_1830_1900 && (hour == "18" && min > 29))) &&
                                                        <option value={hour+":"+min}>{hour+":"+min}</option>
                                                    )

                                                ) }



                                                {/*<option value="16:00">16:00</option>*/}
                                                {/*<option value="16:15">16:15</option>*/}
                                                {/*<option value="16:30">16:30</option>*/}
                                                {/*<option value="16:45">16:45</option>*/}
                                                {/*<option value="17:00">17:00</option>*/}
                                                {/*<option value="17:15">17:15</option>*/}
                                                {/*<option value="17:30">17:30</option>*/}
                                                {/*<option value="17:45">17:45</option>*/}
                                                {/*<option value="18:00">18:00</option>*/}
                                                {/*<option value="18:15">18:15</option>*/}
                                                {/*<option value="18:30">18:30</option>*/}
                                                {/*<option value="18:45">18:45</option>*/}
                                                {/*<option value="19:00">19:00</option>*/}
                                                {/*<option value="19:15">19:15</option>*/}
                                                {/*<option value="19:30">19:30</option>*/}
                                                {/*<option value="19:45">19:45</option>*/}
                                                {/*<option value="20:00">20:00</option>*/}
                                                {/*<option value="20:15">20:15</option>*/}
                                                {/*<option value="20:30">20:30</option>*/}
                                                {/*<option value="20:45">20:45</option>*/}
                                                {/*<option value="21:00">21:00</option>*/}
                                                {/*<option value="21:15">21:15</option>*/}
                                                {/*<option value="21:30">21:30</option>*/}
                                                {/*<option value="21:45">21:45</option>*/}
                                                {/*<option value="22:00">22:00</option>*/}
                                            </Field>
                                            <ErrorMessage name="tijdstip" >
                                                { msg => <div className="field-error">{msg}</div> }
                                            </ErrorMessage>

                                        </div>

                                        <div>




                                            {this.state.server_error ?


                                                <button data-action='afhaalcheckout' className="btn btn-danger checkout-button" type="submit" disabled={this.state.button_disabled}>{this.state.error_message} <br/> Daarna kunt u hier weer klikken om te bestellen.</button>:
                                                <button data-action='afhaalcheckout' className="btn btn-primary checkout-button" type="submit" disabled={this.state.button_disabled}>Bestellen</button> }

                                                <p style={{"color":"#d0d0d0"}}><small>Deze site wordt beschermd door reCAPTCHA en dus zijn de Google
                                                {" "}<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacybeleid</a> en
                                                {" "}<a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Servicevoorwaarden</a> van toepassing.</small></p>
                                                {this.state.server_error && <p className="field-error">Combinatie is niet correct.</p>}
                                                {this.state.captcha_error && <p className="field-error">Bent u een bot? Probeer uw code helemaal opnieuw in te vullen. Als het nog steeds niet werkt, vraag dan aan de bediening om uw code te resetten.</p>}
                                        </div>
                                        {/*<p>test: naam: {this.state.name}, email: {this.state.email}, phone: {this.state.phone}, payment: {this.state.paymentoption}, tijdstip: {this.state.tijdstip} </p>*/}
                                        <PersistFormikValues  name="form-data" storage='sessionStorage' persistInvalid={true} ignoreValues={['tijdstip']} />
                                    </Form>
                                )}
                                </Formik>

                            </div>


                        </div>

                        {/* <!--CART-->*/}
                        <div className="col-lg-6">
                            <div className="checkout-outercart box border">


                                {/*<hr/>*/}
                                {/*<h3>Order Summary</h3>*/}
                                {/*<hr/>*/}
                                {/*{% for item in items %}
                                <div className="cart-row">
                                    <div style="flex:2">{{item.product.name}}</div>
                                    <div style="flex:1">€{{item.product.price}}</div>
                                    <div style="flex:1">x{{item.quantity}}</div>
                                    <div style="flex:1">€{{item.get_total}}</div>
                                </div>
                                {% endfor %}
                                <h5>Items: {{order.get_cart_totalquantity}}</h5>
                                <h5>Total: €{{order.get_cart_total | floatformat:2}}</h5>*/}

                                <Cart
                                    checkoutpage = {true}
                                    cartItems={this.state.cartItems}
                                    total_cost = {this.state.total_cost}
                                    total_quantity = {this.state.total_quantity}
                                    updateCart={this.updateCart} />
                            </div>
                        </div>


                        {/*TEST*/}
                        <div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Checkoutpage;