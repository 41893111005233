import React, {Component} from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import {
  Link
} from "react-router-dom";

export default class Cart extends Component {
    render() {
        const {cartItems} =  this.props;
        return (
            <div className="cart">
                <div className="cartheader">
                    <h3>Winkelmandje</h3>

                </div>
                {(this.props.total_quantity === 0) &&
                <div className="cart-body cart-empty">
                    Uw winkelmandje is leeg
                </div>
                // :
                //     <div className={"cart car-header"}>
                //         You have {this.props.total_quantity} in the cart{" "}
                //     </div>
                }
                <div className="cart-body">
                    {/*<div className="cart-body">*/}

                            <ul className="cartItems">
                                {cartItems.map(item => (




                                    // <li key= {item.product.NasiBamiOption? item.product.id + item.NasiOrBami
                                    //         :
                                    //          item.product.id
                                    // }>

                                    <li key={item.id}>
                                        <div className="cartItem">
                                            <div className="cartItem-row">
                                                {/*{console.log("cartitem", item)}*/}
                                                <div className="cartItem-row-leftside">
                                                    <div className="cartItem-quantity-name">
                                                        <span className="cartItem-quantity">{item.quantity}x</span>
                                                        <span className="cartItem-name">{item.product.name}</span>
                                                    </div>

                                                     <div className="cart-extra-options">
                                                        <ul>
                                                            {item.keuzemenu_options.map((keuzemenu_option, index) => (
                                                                    <li key = {keuzemenu_option.id}>
                                                                        {" "+keuzemenu_option.quantity+ "x "+keuzemenu_option.product.naam}
                                                                    </li>
                                                                )
                                                            )}
                                                            {item.nasi_bami_options.map((nasibami_option, index) => (
                                                                    <li key = {nasibami_option.id}>
                                                                        {" "+nasibami_option.quantity+ "x "+nasibami_option.product.naam}
                                                                    </li>
                                                                )
                                                            )}
                                                            {item.kleinegerechten_options.length!=0 && <b>extra:</b>}
                                                           {item.kleinegerechten_options.map((kleingerecht_option, index) => (
                                                                    <li key = {kleingerecht_option.id}>
                                                                        {" "+kleingerecht_option.quantity+ "x "+kleingerecht_option.product.naam}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="cartItem-row-rightside">

                                                    <span className="cartItem-buttons">

                                                        <IconButton size='small' className="button" onClick={()=>this.props.updateCart(item.product.id,"decrease", [],[],[],item.id)}>
                                                        <RemoveIcon fontSize='small'/>
                                                        </IconButton>
                                                        <IconButton size='small' className="button" onClick={()=>this.props.updateCart(item.product.id,"add", [],[],[],item.id)}>
                                                        <AddIcon fontSize='small'/>
                                                        </IconButton>

                                                    </span>
                                                    <span className="cartItem-price">€{" "+(item.total_cost_without_quantity * item.quantity).toFixed(2)}</span>
                                                    <IconButton size='small' className="cartItem-delButton" onClick={()=>this.props.updateCart(item.product.id,"delete", [],[],[],item.id)}>
                                                        <DeleteForeverIcon fontSize='small'/>
                                                    </IconButton>
                                                </div>

                                            </div>

                                            {/*<hr />*/}
                                        </div>
                                    </li>

                                ))}
                            </ul>

                    {/*</div>*/}
                    {cartItems.length!==0 &&(


                        <div className="total">
                            <div className="cart-totalPrice">
                                <span>
                                    <strong>Totaal:</strong>
                                </span>
                                {/*cartItems.reduce((a,c) => a + c.price*c.count, 0)*/}
                                <span>
                                    <strong>
                                    €{" "+this.props.total_cost.toFixed(2)}
                                    </strong>
                                </span>
                            </div>
                            {/*<Link to="/checkout"><button className="button primary">Proceed</button></Link>*/}

                            {!this.props.checkoutpage
                             &&

                            <div className="cart-button-div">
                                <Link to="/online-afhalen/checkout" className="btn btn-primary cart-button">Naar betalen</Link>
                            </div>
                            }

                        </div>
                    

                    )}


                </div>
            </div>

        );
    }
}

