import React from "react";

// import { makeStyles } from '@material-ui/core/styles';
import {withStyles} from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {

    Link

} from "react-router-dom";
import axios from "axios";

const useStyles = {
    root: {

        // height:"100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: 'space-between',


        maxWidth: 255,
        transition: "transform 0.15s ease-in-out",
        "&:hover": {transform: "scale3d(1.05, 1.05, 1)"},
    },
    media: {
        height: "170px",
    },
    area: {
        // height:"100%",
        flexGrow: "1",
        flexDirection: "column",
        alignItems: "stretch",
        // height:"100%",
        // display:"flex",
        // flexDirection: "column",
        // justifyContent:'space-between',
    },
    lastitem: {
        // disableSpacing: true,
        // marginTop:"auto",
        // paddingTop:"auto"
    }
};

class HomePage extends React.Component {

    constructor() {
        super();
        this.state = {
            homepage_loaded: false,
            text1: "",
            text2: "",
            text3: "",
            text4: "",
            text5: "",
            text6: "",

            text1b: false,
            text2b: false,
            text3b: false,
            text4b: false,
            text5b: false,
            text6b: false,
        }
    }


    componentDidMount() {

        //deviceid.
        //deviceid.
        this.loadNews()
    }

    // returns cart from django
    loadNews = () => {
        axios.get('/api/homepage-news/', {})
            .then((response) => {
                //   console.log("cartt")
                // console.log(response);
                //   //console.log(response.data[0]);
                //   console.log("endcartt")
                this.setState({
                    homepage_loaded: true,
                    text1: response.data['text1'],
                    text2: response.data['text2'],
                    text3: response.data['text3'],
                    text4: response.data['text4'],
                    text5: response.data['text5'],
                    text6: response.data['text6'],

                    text1b: response.data['text1_b'],
                    text2b: response.data['text2_b'],
                    text3b: response.data['text3_b'],
                    text4b: response.data['text4_b'],
                    text5b: response.data['text5_b'],
                    text6b: response.data['text6_b'],
                })


            })
            .catch((error) => {
                // console.log(error);
            });
    }

    render() {
        // const classes = useStyles();
        const {classes} = this.props;
        return (
            <div className="homepage">

                <div className="banner" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/restaurant-outside-4.jpg'})`,
                    backgroundRepeat: 'no-repeat',
                    // width:'200vh'
                    // backgroundSize: '100% 700px'
                }}/>


                <div className="container3 container">

                    <div className="row">
                        <div className="announcement border">
                            <div className=" col-sm-12">

                                <div>

                                    {!this.state.homepage_loaded && (<p>nieuws laden...</p>)}
                                    {
                                        this.state.text1 != "" ? (
                                            this.state.text1b == true ? (<b>{this.state.text1}<br/></b>) : (
                                                <text>{this.state.text1}<br/></text>)
                                        ) : null}

                                    {
                                        this.state.text2 != "" ? (
                                            this.state.text2b == true ? (<b>{this.state.text2}<br/></b>) : (
                                                <text>{this.state.text2}<br/></text>)
                                        ) : null}

                                    {this.state.text3 != "" ? (
                                        this.state.text3b == true ? (<b>{this.state.text3}<br/></b>) : (
                                            <text>{this.state.text3}<br/></text>)
                                    ) : null
                                    }
                                    {this.state.text4 != "" ? (
                                        this.state.text4b == true ? (<b>{this.state.text4}<br/></b>) : (
                                            <text>{this.state.text4}<br/></text>)
                                    ) : null
                                    }{
                                    this.state.text5 != "" ? (
                                        this.state.text5b == true ? (<b>{this.state.text5}<br/></b>) : (
                                            <text>{this.state.text5}<br/></text>)
                                    ) : null
                                }{
                                    this.state.text6 != "" ? (
                                        this.state.text6b == true ? (<b>{this.state.text6}<br/></b>) : (
                                            <text>{this.state.text6}<br/></text>)
                                    ) : null

                                }


                                    {/*<p>*/}
                                    {/*    Zelfbediening is tijdelijk weer niet mogelijk vanwege covid-19.<br/>*/}
                                    {/*    Echter kunt u  onbeperkt gerechten van het buffet,*/}
                                    {/*    wokken en bakplaat blijven bestellen via uw eigen telefoon. <br/>*/}
                                    {/*    Wij serveren*/}
                                    {/*    het vervolgens aan tafel voor u!</p>*/}

                                </div>
                                {/*<div>  <Link style={{fontSize:"larger"}}to="/bestel">Klik hier voor zelfbediening</Link></div>*/}
                            </div>
                        </div>


                    </div>

                    {/*<!-- CHRISTMES MENU, DELETE AFTER CHRIMSTAS-->*/}
                    {/*<!-- CHRISTMES MENU, DELETE AFTER CHRIMSTAS-->*/}
                    {/*<!-- CHRISTMES MENU, DELETE AFTER CHRIMSTAS-->*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-sm-8 price-box border">*/}
                    {/*        <p style={{textAlignVertical: "center", textAlign: "center",}}><b>🎄 Kerst er weer, kom bij ons*/}
                    {/*            voor een speciale kerst! </b>🎄<br/>*/}
                    {/*            U kunt reserveren via ons reserveer systeem <Link to="/reserveren"> hier</Link>. Als u*/}
                    {/*            eerder wilt reserveren, dan kunt u ons bellen of emailen!</p>*/}


                    {/*        <p>⭐Kerstdiner 1ste & 2e Kerstdag:</p>*/}
                    {/*        <div className="wok-prices">*/}
                    {/*            <p>Kerstdiner: € 30,00 p.p. (excl. drank)<br/>*/}
                    {/*                Kinderen t/m 11 jaar: € 2,20 per leeftijdsjaar (excl. drank)<br/></p><p>*/}
                    {/*            Kerstdiner: € 38,00 p.p. (incl. drank 2.5 uur)<br/>*/}
                    {/*            Kinderen t/m 11 jaar: € 3,00 per leeftijdsjaar (incl. drank 2.5 uur)*/}
                    {/*        </p></div>*/}
                    {/*        /!*<p style={{textAlignVertical: "center", textAlign: "center",}}>—</p>*!/*/}
                    {/*        <p>⭐1ste Kerstdag tijden:</p>*/}
                    {/*        <div className="wok-prices">*/}
                    {/*            Zitting 1: 16:00 & 16:30 (2.5 uur)<br/>*/}
                    {/*            Zitting 2: 18:45 & 19:15 & 19:45 (tot einde)*/}
                    {/*        </div>*/}
                    {/*        <br/>*/}

                    {/*        <p>⭐2e Kerstdag tijden:</p>*/}
                    {/*        <div className="wok-prices">Zitting 1: 16:00 & 16:30 (2.5 uur)<br/>*/}
                    {/*            Zitting 2: 18:45 & 19:15 & 19:45 (tot einde)*/}
                    {/*        </div>*/}
                    {/*        <br/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<!-- CHRISTMES MENU, DELETE AFTER CHRIMSTAS-->*/}
                    {/*<!-- CHRISTMES MENU, DELETE AFTER CHRIMSTAS-->*/}
                    {/*<!-- CHRISTMES MENU, DELETE AFTER CHRIMSTAS-->*/}
                    {/*<!-- CHRISTMES MENU, DELETE AFTER CHRIMSTAS-->*/}

                    {/*<!-- CHRISTMES MENU, DELETE AFTER CHRIMSTAS-->*/}

                    {/*<div className="row">*/}

                    {/*    <div className="col-sm-12">*/}
                    {/*        /!*<div>*!/*/}
                    {/*        /!*    <img className="img-stretch-center"src={process.env.PUBLIC_URL + '/images/home-2a.jpg'} alt="Image of the restaurant"/>*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*        <div>*/}
                    {/*            In een authentieke sfeer vindt u bij Wokrestaurant Peking Garden in Baarlo*/}
                    {/*            het beste wat de Aziatische keuken te bieden heeft.*/}
                    {/*            Chinees wokken, Japans tepanyakigrillen en babecuen onder een dak!*/}
                    {/*        </div>*/}


                    {/*    </div>*/}
                    {/*    /!*<div className="col-sm-4">*!/*/}
                    {/*    /!*    <img src={process.env.PUBLIC_URL + '/images/home-1.jpg'} alt="Image of the restaurant"/>*!/*/}
                    {/*    /!*</div>*!/*/}

                    {/*</div>*/}


                    {/*<img src={process.env.PUBLIC_URL + '/images/restaurant-outside.jpg'} className="img-responsive" alt="image" width="100vw" height="auto"/>*/}
                    <div className="homepage-cards">

                        <Card className={classes.root}>
                            <CardActionArea
                                component={Link} to="/afhalen" style={{textDecoration: 'inherit', color: 'inherit'}}
                                className={classes.area}>
                                <CardMedia
                                    className={classes.media}
                                    image={process.env.PUBLIC_URL + '/images/afhalen-4.jpg'}
                                    title="Afhalen"
                                />
                                <CardContent>
                                    <Typography className="restaurant-red" gutterBottom variant="h5" component="h2">
                                        Afhalen
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Ons afhaalrestaurant staat bekend om ons dagelijks verse ingrediënten en de
                                        vlotte services.

                                    </Typography>
                                </CardContent>

                            </CardActionArea>
                            <CardActions className={classes.lastitem}>
                                <Button component={Link} to="/afhalen" size="small" color="primary">
                                    Meer Informatie
                                </Button>
                            </CardActions>

                        </Card>

                        <Card className={classes.root}>
                            <CardActionArea
                                component={Link} to="/wokken" style={{textDecoration: 'inherit', color: 'inherit'}}
                                className={classes.area}>
                                <CardMedia
                                    className={classes.media}
                                    image={process.env.PUBLIC_URL + '/images/wokken-2.jpg'}
                                    title="Wokken"
                                />
                                <CardContent>
                                    <Typography className="restaurant-red" gutterBottom variant="h5" component="h2">
                                        Wokken
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Zelf onbeperkt uw eigen gerechten samen stellen.
                                        Wokken, tepanyaki grillen, barbecuen en een lopend buffet.

                                    </Typography>
                                </CardContent>


                            </CardActionArea>
                            <CardActions className={classes.lastitem}>
                                <Button component={Link} to="/wokken" className="flexend" size="small" color="primary">
                                    Meer Informatie
                                </Button>
                            </CardActions>
                        </Card>

                        <Card className={classes.root}>
                            <CardActionArea
                                component={Link} to="/restaurant" style={{textDecoration: 'inherit', color: 'inherit'}}
                                className={classes.area}>
                                <CardMedia
                                    className={classes.media}
                                    image={process.env.PUBLIC_URL + '/images/wokken-1.jpg'}
                                    title="Restaurant"
                                />
                                <CardContent>
                                    <Typography className="restaurant-red" gutterBottom variant="h5" component="h2">
                                        Restaurant
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        U kunt bij ons vanzelfsprekend terecht voor à la carte.
                                        Wij hebben een zeer uitgebreide kaart met bekende Chinese gerechten.
                                    </Typography>
                                </CardContent>

                            </CardActionArea>
                            <CardActions className={classes.lastitem}>
                                <Button component={Link} to="/restaurant"
                                        className="flexend" size="small" color="primary">
                                    Meer Informatie
                                </Button>
                            </CardActions>
                        </Card>


                        <Card className={classes.root}>
                            <CardActionArea
                                component={Link} to="/catering" style={{textDecoration: 'inherit', color: 'inherit'}}
                                className={classes.area}>
                                <CardMedia
                                    className={classes.media}
                                    image={process.env.PUBLIC_URL + '/images/catering-0.jpg'}
                                    title="Catering"
                                />
                                <CardContent>
                                    <Typography className="restaurant-red" gutterBottom variant="h5" component="h2">
                                        Catering
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Heeft u een feestje thuis of op het bedrijf met meer dan 20 personen,
                                        laat het ons dan weten. Wij bezorgen voor u een heerlijk buffet.
                                    </Typography>
                                </CardContent>

                            </CardActionArea>
                            <CardActions className={classes.lastitem}>
                                <Button component={Link} to="/catering" size="small" color="primary">
                                    Meer Informatie
                                </Button>
                            </CardActions>

                        </Card>


                        {/*<Card className={classes.root}>*/}
                        {/*    <CardActionArea*/}
                        {/*        component={Link} to="/bijbaan"  style={{ textDecoration: 'inherit', color: 'inherit' }}*/}
                        {/*        className={classes.area}>*/}
                        {/*        <CardMedia*/}
                        {/*            className={classes.media}*/}
                        {/*            image={process.env.PUBLIC_URL + '/images/bijbaancard.jpg'}*/}
                        {/*            title="Catering"*/}
                        {/*        />*/}
                        {/*        <CardContent>*/}
                        {/*            <Typography className="restaurant-red" gutterBottom variant="h5" component="h2">*/}
                        {/*                Bijbaan*/}
                        {/*            </Typography>*/}
                        {/*            <Typography variant="body2" color="textSecondary" component="p">*/}
                        {/*                Bent u opzoek naar een bijbaantje? Klik hier voor meer informatie!*/}
                        {/*            </Typography>*/}
                        {/*        </CardContent>*/}

                        {/*    </CardActionArea>*/}
                        {/*    <CardActions className={classes.lastitem}>*/}
                        {/*            <Button component={Link} to="/bijbaan" size="small" color="primary">*/}
                        {/*                Meer Informatie*/}
                        {/*            </Button>*/}
                        {/*        </CardActions>*/}

                        {/*</Card>*/}
                    </div>


                </div>
            </div>
        );
    }
};


export default withStyles(useStyles)(HomePage);